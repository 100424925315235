import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { CreateDeviceMutation } from '../__generated__/CreateDeviceMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation CreateDeviceMutation(
    $input: CreateDeviceInput!
  ) {
    createDevice(input: $input) {
      device {
        id
        mongoId
        name
        collectVersion
        expectActive
        lastFetched
        lastFetchedCollectVersion
        location
        config {
          activityTimeout
          hardReset
          language
          languagesToShow
          startScreenText
          demo
          endMessageTime
          alertOnPageClose
          reverseSmileys
        }
      }
    }
  }
`;



export const useCreateDeviceMutation = ():[(config: UseMutationConfig<CreateDeviceMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<CreateDeviceMutation>(mutation);
  return [commit, isInFlight];
};






// import {commitMutation, graphql} from 'react-relay';
// import {ConnectionHandler} from 'relay-runtime';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation CreateDeviceMutation(
//     $input: CreateDeviceInput!
//   ) {
//     createDevice(input: $input) {
//       device {
//         id
//         mongoId
//         name
//         collectVersion
//         expectActive
//         lastFetched
//         lastFetchedCollectVersion
//         location
//         config {
//           activityTimeout
//           hardReset
//           language
//           languagesToShow
//           startScreenText
//           demo
//           endMessageTime
//           alertOnPageClose
//           reverseSmileys
//         }
//       }
//     }
//   }
// `;

// function commit({accountId, name, location}) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           accountId,
//           name,
//           location,
//         },
//       },
//       updater: (store) => {
//         const payload = store.getRootField('createDevice');
//         const newDevice = payload.getLinkedRecord('device');
//         const account = store.get(accountId);
//         const connection = ConnectionHandler.getConnection(
//           account,
//           'DeviceList_devices',
//         );
//         const newEdge = ConnectionHandler.createEdge(
//           store,
//           connection,
//           newDevice,
//           'AccountDeviceEdge',
//         );
//         ConnectionHandler.insertEdgeBefore(connection, newEdge);
//       },
//     },
//   );
// }

// export default {commit};
