// import {commitMutation, graphql} from 'react-relay';
// import {ConnectionHandler} from 'relay-runtime';
// import environment from 'data/environment';
// import { useEnvironment } from '../services';
import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { CreateTemplateMutation } from '../__generated__/CreateTemplateMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation CreateTemplateMutation(
    $input: CreateTemplateInput!
  ) {
    createTemplate(input: $input) {
      survey {
        id
        mongoId
        name
        isDraft
        isTemplate
      }
    }
  }
`;


export const useCreateTemplateMutation = ():[(config: UseMutationConfig<CreateTemplateMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<CreateTemplateMutation>(mutation);
  return [commit, isInFlight];
};

// function commit({id}: {id: any}) {

//   const [ commitCurrentMutation ] = getCurrentMutation();

//   commitCurrentMutation({
//     variables: {
//       input: {
//         id,
//       }
//     },
//     updater: (store) => {

//       const payload = store.getRootField('createTemplate');
//       const record = payload.getLinkedRecord('survey');

//       const rootRecord = store.getRoot();
//       const connection: any = ConnectionHandler.getConnection(
//         rootRecord, //       'client:root',
//         'SurveysQuery_adminSurveys',
//       );

//       const newEdge = ConnectionHandler.createEdge(
//         store,
//         connection,
//         record,
//         'SurveyEdge',
//       );
//       ConnectionHandler.insertEdgeBefore(connection, newEdge);
//     },

//     onError: (error: any) => {
//       // handleError(error);
//     },
//   });

// }

// export default {commit};




// // import graphql from 'babel-plugin-relay/macro';
// // import { useMutation, UseMutationConfig } from 'react-relay';
// // import { PopulateReportMutation } from '../__generated__/PopulateReportMutation.graphql';
// // import { Disposable } from 'relay-runtime';

// // const mutation = graphql`
// //   mutation PopulateReportMutation(
// //     $input: PopulateReportInput!
// //   ) {
// //     populateReport(input: $input) {
// //       report {
// //         id
// //       }
// //     }
// //   }
// // `;

// // export const usePopulateReportMutation = ():[(config: UseMutationConfig<PopulateReportMutation>) => Disposable, boolean] => {
// //   const [commit, isInFlight] = useMutation<PopulateReportMutation>(mutation);
// //   return [commit, isInFlight];
// // };
