import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import {
  useCreateReplacementDeviceMutation
} from '../../../../mutations';
import { useNotification } from '../../../../hooks/useNotification';
import { Typography } from '@mui/material';



/**
 * 
 * @param props 
 * @returns 
 */
export function CreateReplacementDeviceDialog(props: any) {

  const { open, onClose } = props;

  const device = props.device || {};

  const { success, handleError } = useNotification();

  const [commitCreateReplacementDeviceMutation] = useCreateReplacementDeviceMutation();

  /**
   * 
   */
  function save() {
    commitCreateReplacementDeviceMutation({
      variables: {
        input: {
          deviceId: device.id,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        success('A replacement device is successfully created.');
      },
      onError: (error) => {
        handleError(error);
      },
    });

    onClose();
  }



  /**
   * 
   */
  function cancel() {
    onClose();
  }



  /**
   * 
   */
  return (
    <Dialog
      title="Create replacement device"
      open={open}
      onClose={onClose}
    >

      <DialogContent>

        <Typography
          sx={{
            width: '100%',
            mb: 2,
          }}
          variant="h4">
          Create a replacement device
        </Typography>

        <Typography
          sx={{
            width: '100%',
            mb: 2,
          }}
          variant="body1">
          A new device will be created.
          When a replacement device is turned on,
          it will take place of the old device.
          The old device will be deleted automatically.
        </Typography>
        <Typography
          sx={{
            width: '100%',
            mb: 4,
          }}
          variant="body1">
          The device to replace:
          <br />
          <strong>{device.name}</strong><br />
          ID {device.mongoId}
        </Typography>

      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          disableElevation={true}
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={save}
        >
          Create
        </Button>
      </DialogActions>

    </Dialog>
  );

}

