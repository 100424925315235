/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateAccountActiveInput = {
    accountId: string;
    active: boolean;
    clientMutationId?: string | null | undefined;
};
export type UpdateAccountActiveMutationVariables = {
    input: UpdateAccountActiveInput;
};
export type UpdateAccountActiveMutationResponse = {
    readonly updateAccountActive: {
        readonly account: {
            readonly id: string;
            readonly active: boolean | null;
        } | null;
    } | null;
};
export type UpdateAccountActiveMutation = {
    readonly response: UpdateAccountActiveMutationResponse;
    readonly variables: UpdateAccountActiveMutationVariables;
};



/*
mutation UpdateAccountActiveMutation(
  $input: UpdateAccountActiveInput!
) {
  updateAccountActive(input: $input) {
    account {
      id
      active
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAccountActivePayload",
    "kind": "LinkedField",
    "name": "updateAccountActive",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAccountActiveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAccountActiveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "51c928fc2193f1a2a540712f883ce626",
    "id": null,
    "metadata": {},
    "name": "UpdateAccountActiveMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAccountActiveMutation(\n  $input: UpdateAccountActiveInput!\n) {\n  updateAccountActive(input: $input) {\n    account {\n      id\n      active\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '408602d5abd5161698084fb554c6fae1';
export default node;
