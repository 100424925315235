import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { ImpersonateUserMutation } from '../__generated__/ImpersonateUserMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation ImpersonateUserMutation(
    $input: ImpersonateUserInput!
  ) {
    impersonateUser(input: $input) {
      url
    }
  }
`;


export const useImpersonateUserMutation = ():[(config: UseMutationConfig<ImpersonateUserMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<ImpersonateUserMutation>(mutation);
  return [commit, isInFlight];
};



// import {commitMutation, graphql} from 'react-relay';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation ImpersonateUserMutation(
//     $input: ImpersonateUserInput!
//   ) {
//     impersonateUser(input: $input) {
//       url
//     }
//   }
// `;

// function commit({userId, callbackUrl}, callback) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           userId,
//           callbackUrl,
//         },
//       },
//       onCompleted: (res) => {
//         callback(null, res.impersonateUser.url);
//       },
//       onError: (err) => {
//         callback(err);
//       },
//     },
//   );
// }

// export default {commit};
