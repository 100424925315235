import graphql from 'babel-plugin-relay/macro';

export const AccountsQuery = graphql`
  query AccountsQuery {

    accounts {
      edges {
        node {
          id
          mongoId
          name
          active
          identifier
        }
      }
    }

  }
`;
