
import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { UpdateDeviceExpectActiveMutation } from '../__generated__/UpdateDeviceExpectActiveMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation UpdateDeviceExpectActiveMutation(
    $input: UpdateDeviceExpectActiveInput!
  ) {
    updateDeviceExpectActive(input: $input) {
      device {
        id
        expectActive
      }
    }
  }
`;


export const useUpdateDeviceExpectActiveMutation = ():[(config: UseMutationConfig<UpdateDeviceExpectActiveMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<UpdateDeviceExpectActiveMutation>(mutation);
  return [commit, isInFlight];
};





// import {commitMutation, graphql} from 'react-relay';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation UpdateDeviceExpectActiveMutation(
//     $input: UpdateDeviceExpectActiveInput!
//   ) {
//     updateDeviceExpectActive(input: $input) {
//       device {
//         id
//         expectActive
//       }
//     }
//   }
// `;

// function commit({deviceId, expectActive}) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           deviceId,
//           expectActive,
//         },
//       },
//     },
//   );
// }

// export default {commit};
