/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DevicesByAccountQueryVariables = {
    accountId?: string | null | undefined;
};
export type DevicesByAccountQueryResponse = {
    readonly devicesByAccount: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string | null;
                readonly location: string | null;
                readonly timezone: string | null;
                readonly tags: ReadonlyArray<string | null> | null;
                readonly mongoId: string | null;
                readonly collectVersion: string | null;
                readonly expectActive: boolean | null;
                readonly lastFetched: string | null;
                readonly isNewCollect: boolean | null;
                readonly isOnline: boolean | null;
                readonly deviceType: string | null;
                readonly config: {
                    readonly activityTimeout: number | null;
                    readonly hardReset: number | null;
                    readonly language: string | null;
                    readonly languagesToShow: ReadonlyArray<string | null> | null;
                    readonly startScreenText: string | null;
                    readonly demo: boolean | null;
                    readonly endMessageTime: number | null;
                    readonly alertOnPageClose: boolean | null;
                    readonly reverseSmileys: boolean | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};
export type DevicesByAccountQuery = {
    readonly response: DevicesByAccountQueryResponse;
    readonly variables: DevicesByAccountQueryVariables;
};



/*
query DevicesByAccountQuery(
  $accountId: ID
) {
  devicesByAccount(accountId: $accountId) {
    edges {
      node {
        id
        name
        location
        timezone
        tags
        mongoId
        collectVersion
        expectActive
        lastFetched
        isNewCollect
        isOnline
        deviceType
        config {
          activityTimeout
          hardReset
          language
          languagesToShow
          startScreenText
          demo
          endMessageTime
          alertOnPageClose
          reverseSmileys
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      }
    ],
    "concreteType": "DeviceConnection",
    "kind": "LinkedField",
    "name": "devicesByAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tags",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mongoId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collectVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expectActive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastFetched",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isNewCollect",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOnline",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deviceType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceConfig",
                "kind": "LinkedField",
                "name": "config",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activityTimeout",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hardReset",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "language",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "languagesToShow",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startScreenText",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "demo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endMessageTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "alertOnPageClose",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reverseSmileys",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DevicesByAccountQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DevicesByAccountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "68aead985e73f35e2d9681657c717ece",
    "id": null,
    "metadata": {},
    "name": "DevicesByAccountQuery",
    "operationKind": "query",
    "text": "query DevicesByAccountQuery(\n  $accountId: ID\n) {\n  devicesByAccount(accountId: $accountId) {\n    edges {\n      node {\n        id\n        name\n        location\n        timezone\n        tags\n        mongoId\n        collectVersion\n        expectActive\n        lastFetched\n        isNewCollect\n        isOnline\n        deviceType\n        config {\n          activityTimeout\n          hardReset\n          language\n          languagesToShow\n          startScreenText\n          demo\n          endMessageTime\n          alertOnPageClose\n          reverseSmileys\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f20c8fe62403fd8ca9a2baf39d81c68b';
export default node;
