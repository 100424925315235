import graphql from 'babel-plugin-relay/macro';



export const DevicesByAccountQuery = graphql`
query DevicesByAccountQuery(
    $accountId: ID
  ) {

  devicesByAccount(
    accountId: $accountId
  ) {
    edges {
      node {
        id
        name
        location
        timezone
        tags
        mongoId
        collectVersion
        expectActive
        lastFetched
        isNewCollect
        isOnline
        deviceType

        config {
          activityTimeout
          hardReset
          language
          languagesToShow
          startScreenText
          demo
          endMessageTime
          alertOnPageClose
          reverseSmileys
        }

      }
    }
  }

}
`;


