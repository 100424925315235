import { Box, Button, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
// import { useLocale } from '../../contexts';

const styles: any = {
  top: 0,
  left: 0,
  position: 'fixed',
  width: '100%',
  height: '100%',
  backgroundColor: '#00abc5',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export function AuthError({ error }: { error?: any }) {

  // Error message 
  const errorMessage = (error && error.message) ? (error.message || null) : null;

  const {
    loginWithRedirect,
  } = useAuth0();

  // const { intl } = useLocale();

  return (
    <Box sx={{ ...styles }}>
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          sx={{ color: 'white' }}
          variant="h1"
          component="div"
          noWrap={true}
          gutterBottom={true}
        >
          Auth Error
        </Typography>

        {errorMessage ?
          <Typography
            sx={{
              color: 'white',
              mb: 3,
            }}
            variant="body1"
            component="div"
            gutterBottom={true}
          >
            {errorMessage}
          </Typography>
          : null}

        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={() => loginWithRedirect()}>
          Retry
        </Button>
      </Box>
    </Box>
  )
}
