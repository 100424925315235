import {ChangeEvent, useState} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContesntText from '@mui/material/DialogContentText';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import MenuItem from '@mui/material/MenuItem';
import {
  useCreateAccountMutation,
} from '../../../../mutations';
import { 
  DEFAULT_LOCALE,
  LOCALE_EN_GB,
  LOCALE_SV_SE,
} from '../../../../config';
import Grid from '@mui/material/Grid';





const styles = {
  nameInput: {
    width: 500,
  },
};

export function CreateAccountDialog(props: any) {

  const [commitCreateAccountMutation] = useCreateAccountMutation();

  const { open, onClose } = props;

  const [name, setName] = useState<string>('');
  const [identifier, setIdentifier] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [language, setLanguage] = useState<string>(DEFAULT_LOCALE);

  function setInitialState() {
    setName('');
    setIdentifier('');
    setPassword('');
    setLanguage(DEFAULT_LOCALE);
  }

  function updateName(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function updateIdentifier(event: ChangeEvent<HTMLInputElement>) {
    setIdentifier(event.target.value);
  }

  function updatePassword(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  function updateLanguage(event: SelectChangeEvent) {
    setLanguage(event.target.value as string);
  }

  function save () {

    commitCreateAccountMutation({
      variables: {
        input: {
          name: name || '',
          identifier,
          password,
          language,
        },
      },
      updater: (store, payload) => {
	      // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
      },
    });

    // CreateAccountMutation.commit({
    //   name: name || '',
    //   identifier,
    //   password,
    //   language,
    // });
    setInitialState();
    onClose();
  }

  function cancel () {
    setInitialState();
    onClose();
  }



  return (
    <Dialog
      title="Create a new account"
      open={open}
      onClose={onClose}
    >

      <DialogContent>

        <Grid
          flexDirection="column"
          container>
          <Grid 
            sx={{
              mb: 1,
            }}
            item>
            <TextField
              label="Name"
              helperText="e.g. 'ICA Maxi Väst'"
              value={name}
              onChange={updateName}
              style={styles.nameInput}
            />
          </Grid>
          <Grid 
            sx={{
              mb: 1,
            }}
            item>
            <TextField
              label="Identifier"
              helperText="e.g. 'maxivast'"
              value={identifier}
              onChange={updateIdentifier}
            />
          </Grid>
          <Grid 
            sx={{
              mb: 1,
            }}
            item>
            <TextField
              label="Password"
              value={password}
              onChange={updatePassword}
              />
          </Grid>
          <Grid 
            sx={{
              mb: 1,
            }}
            item>
            <FormControl fullWidth>
              <InputLabel id="select-label">
                Language
              </InputLabel>
              <Select
                labelId="select-label"
                label="Language"
                value={language}
                onChange={updateLanguage}
              >
                <MenuItem value={LOCALE_SV_SE}>
                  Swedish
                </MenuItem>
                <MenuItem value={LOCALE_EN_GB}>
                  English
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button 
          variant="contained"
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button 
          variant="contained"
          color="primary"
          onClick={save}
        >
          Save
        </ Button>
      </DialogActions>
    </Dialog>
  );

}

