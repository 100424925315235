import { useSnackbar } from 'notistack';

export function useNotification() {

  const { enqueueSnackbar } = useSnackbar();

  /**
   * Show error notification
   * @param message 
   */
  function error(message: string): void {
    enqueueSnackbar(message, {variant: 'error'});
    return;
  }

  /**
   * Show warning notification
   * @param message 
   */
  function warning(message: string): void {
    enqueueSnackbar(message, {variant: 'warning'});
    return;
  }

  /**
   * Show success notification
   * @param message 
   */
  function success(message: string): void {
    enqueueSnackbar(message, {variant: 'success'});
    return;
  }

  /**
   * Parse graphql api stringified error response
   * @param error 
   */
  function handleError(error: any): void {
    let errors = [];
    if (error && error.message) {
      const parts = error.message.split('\'');
      errors = parts.reduce((a: any, c: any) => {
        let n = c;
        if (c.indexOf(':') === 0) {
          n = c.slice(1, c.length);
        }
        try {
          const parsed = JSON.parse(n.trim());
          if (Array.isArray(parsed)) {
            parsed.forEach((p: any) => {
              if (p && p.message) {
                a.push(p.message);
              }
            });
          }
        } catch (e){
          // silence error
          // if we can't parse it's not json
        }
        return a;
      }, []);
    }

    if (!errors && !errors.length) {
      errors = [error.message]
    }
    errors.forEach((message: string) => {
      enqueueSnackbar(message, {variant: 'error'});
    });
    return;
  }

  /**
   * return
   */
  return {
    enqueueSnackbar,
    error,
    warning,
    success,
    handleError,
  };
}
