import {
  ChangeEvent,
  useState,
} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  useUpdateDeviceCollectVersionMutation
} from '../../../../mutations';


/**
 * 
 * @param props 
 * @returns 
 */
export function UpdateDeviceCollectVersionDialog(props: any) {

  const { open, onClose } = props;

  const propsDevice = props.device || {};

  const [version, setVersion] = useState(propsDevice.collectVersion || '');

  const [commitUpdateDeviceCollectVersionMutation] = useUpdateDeviceCollectVersionMutation();

  /**
   * 
   * @param event 
   */
  function updateVersion(event: ChangeEvent<HTMLInputElement>) {
    setVersion(event.target.value);
  }

  /**
   * 
   */
  function save() {
    // UpdateDeviceCollectVersionMutation.commit({
    //   deviceId: device.id,
    //   collectVersion: version,
    // });

    commitUpdateDeviceCollectVersionMutation({
      variables: {
        input: {
          deviceId: propsDevice.id,
          collectVersion: version,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
      },
    });

    onClose();
  }

  /**
   * 
   */
  function cancel() {
    setVersion(propsDevice.collectVersion || '');
    onClose();
  }


  /**
   * 
   */
  return (
    <Dialog
      title="Update Touch&Tell Collect version for device"
      open={open}
      onClose={onClose}
    >

      <DialogContent>
        <TextField
          label="Version"
          value={version}
          onChange={updateVersion}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          disableElevation={true}
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={save}
        >
          Save
        </Button>
      </DialogActions>

    </Dialog>
  );

}
