import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { DeleteDeviceMutation } from '../__generated__/DeleteDeviceMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation DeleteDeviceMutation(
    $input: DeleteDeviceInput!
  ) {
    deleteDevice(input: $input) {
      id
      accountId
    }
  }
`;


export const useDeleteDeviceMutation = ():[(config: UseMutationConfig<DeleteDeviceMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<DeleteDeviceMutation>(mutation);
  return [commit, isInFlight];
};



// import {commitMutation, graphql} from 'react-relay';
// import {ConnectionHandler} from 'relay-runtime';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation DeleteDeviceMutation(
//     $input: DeleteDeviceInput!
//   ) {
    // deleteDevice(input: $input) {
    //   id
    //   accountId
    // }
//   }
// `;

// function commit({id}) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           id,
//         },
//       },
//       updater: (store) => {
//         const payload = store.getRootField('deleteDevice');
//         const deviceId = payload.getDataID();
//         const accountId = payload.getValue('accountId');
//         const account = store.get(accountId);
//         const connection = ConnectionHandler.getConnection(
//           account,
//           'DeviceList_devices',
//         );
//         ConnectionHandler.deleteNode(connection, deviceId);
//       },
//     },
//   );
// }

// export default {commit};
