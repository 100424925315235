import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useDebounce } from '../../../../hooks';



/**
 * 
 * @param props 
 * @returns 
 */
export default function FilterInput(props: any) {

  const { debounceTime, onChange, value, label, sx } = props;

  const styles = sx || {};

  const [inputValue, setInputValue] = useState<any>(value);

  const debouncedValue = useDebounce(inputValue, (debounceTime || 300));



  /**
   * Use effect
   */
  useEffect(() => {
    onChange(inputValue);
    // eslint-disable-next-line
  }, [debouncedValue])


  /**
   * Set value and call onChange
   */
  function handleChange(newValue: any) {
    if (!newValue || !newValue.target) {
      return;
    }
    setInputValue(newValue.target.value);
  }

  /**
   * Return
   */
  return <TextField
    sx={{ ...styles }}
    label={label}
    value={inputValue}
    onChange={(event) => handleChange(event)}
  />;

}
