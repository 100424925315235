import { useState } from 'react';
import { grey500 } from '../../../../theme';
import Fab from '@mui/material/Fab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import {
  UserListItem,
  CreateUserDialog,
} from '../../components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import { usePreloadedQuery } from 'react-relay';



const styles = {
  card: {
    position: 'relative',
  },
  cardActions: {
    padding: '0px 16px',
  },
  table: {
    tableLayout: 'auto',
  },
  tableBody: {
    overflow: 'visible',
  },
  noUsersMessage: {
    color: grey500,
    fontStyle: 'italic',
  },
};



export function UserList(props: any) {

  const account = props.account || {};

  const { queryRef, query } = props;
  const preloaded: any = usePreloadedQuery(query, queryRef);
  const users = preloaded.usersByAccount;

  const userEdges = (users && users.edges) || [];
  const userNodes = userEdges.map((edge: any) => edge ? edge.node : null).filter(Boolean);

  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);

  function showAddUserDialog() {
    setAddUserDialogOpen(true);
  }

  function hideAddUserDialog() {
    setAddUserDialogOpen(false);
  }



  return (
    <Accordion
      sx={{
        border: '1px solid #c4e2e7',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      <AccordionSummary
        sx={{
          marginBottom: '-1px',
          borderBottom: '1px solid #c4e2e7',
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container flexDirection="column">
          <Grid item>
            <Typography variant="h6">
              Users
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          overflowX: 'scroll',
        }}
      >
        <Grid container flexDirection="column">
          <Grid item>
            <Fab
              onClick={showAddUserDialog}
              size="small"
            >
              <AddIcon />
            </Fab>
          </Grid>
          <Grid item>

            {
              userNodes.length > 0 ?
                (
                  <Table sx={styles.table}>
                    <TableBody sx={styles.tableBody}>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Language</TableCell>
                        <TableCell>Weekly email</TableCell>
                        <TableCell>Monthly email</TableCell>
                        <TableCell>Permissions</TableCell>
                        <TableCell>Create login link</TableCell>
                        <TableCell>Impersonate</TableCell>
                        <TableCell>Impersonate (New)</TableCell>
                      </TableRow>
                      {userNodes.map((user: any) =>
                        <UserListItem
                          key={user.id}
                          account={account}
                          user={user} />
                      )}
                    </TableBody>
                  </Table>
                )
                :
                (
                  <div style={styles.noUsersMessage}>
                    No users in this account
                  </div>
                )
            }


          </Grid>
        </Grid>
      </AccordionDetails>
      <CreateUserDialog
        account={account}
        open={addUserDialogOpen}
        onClose={hideAddUserDialog}
      />
    </Accordion>
  );

}

// export default createFragmentContainer(
//   UserList,
//   { userlist: graphql`
//     fragment UserList_account on Account {
//       users(
//         first: 2147483647 # max GraphQLInt
//       ) @connection(key: "UserList_users") {
//         edges {
//           node {
//             id
//             ...UserListItem_user
//           }
//         }
//       }
//       ...UserListItem_account
//       ...CreateUserDialog_account
//     }
//   `},
// );


