import { ChangeEvent, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  useCreateDeviceMutation,
} from '../../../../mutations';
import Grid from '@mui/material/Grid';
import {
  DEFAULT_DEVICE_TYPE,
  DEVICE_TYPE_TRANSLATIONS,
} from '../../.././../config';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';



const styles = {
  nameInput: {
    width: 500,
  },
  locationInput: {
    width: 500,
  },
};

export function CreateDeviceDialog(props: any) {

  const { open, deviceCount, onClose, account } = props;

  const n = account && account.name ? account.name : '';
  const [name, setName] = useState<string>(`${n} ${props.deviceCount + 1}`);
  const [location, setLocation] = useState<string>('');
  const [deviceType, setDeviceType] = useState<string>(DEFAULT_DEVICE_TYPE);

  const [commitCreateDeviceMutation] = useCreateDeviceMutation();


  /**
   * updateName
   * @param event 
   */
  function updateName(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  /**
   * updateLocation
   * @param event 
   */
  function updateLocation(event: ChangeEvent<HTMLInputElement>) {
    setLocation(event.target.value);
  }

  /**
   * save 
   */
  function save() {

    commitCreateDeviceMutation({
      variables: {
        input: {
          accountId: account.id,
          name: (name || ''),
          location,
          deviceType,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
        window.location.href = window.location.href.toString();
      },
      onError: (error) => {
        // handleError(error);
      },
    });

    // CreateDeviceMutation.commit({
    //   accountId: account.id,
    //   name: (name || ''),
    //   location,
    // });
    setLocation('');
    setName(`${account.name} ${Number(deviceCount) + 2}`);
    onClose();
  }

  /**
   * Cancel
   */
  function cancel() {
    setLocation('');
    setName(`${account ? account.name : ''} ${Number(deviceCount) + 1}`);
    onClose();
  }

  /**
   * Update language
   * @param event 
   */
  function updateLanguage(event: SelectChangeEvent) {
    setDeviceType(event.target.value);
  }

  return (
    <Dialog
      title="Create a new device"
      open={open}
      onClose={onClose}
    >
      <DialogContent>

        <Grid container>
          <Grid
            sx={{
              mb: 1,
            }}
            item>
            <TextField
              label="Name"
              value={name}
              onChange={updateName}
              style={styles.nameInput}
            />
          </Grid>
          <Grid
            sx={{
              mb: 1,
            }}
            item>
            <TextField
              label="Location (optional)"
              value={location}
              onChange={updateLocation}
              style={styles.locationInput}
            />
          </Grid>
          <Grid
            sx={{
              mb: 1,
            }}
            item>
            <Select
              labelId="select-label"
              value={deviceType}
              label="Language"
              onChange={updateLanguage}
            >
              {DEVICE_TYPE_TRANSLATIONS.map(({ value, label }) =>
                <MenuItem value={value}>
                  {label}
                </MenuItem>
              )}
            </Select>
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disableElevation={true}
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={save}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

}

