/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateTemplateInput = {
    id: string;
    destinationAccount?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type CreateTemplateMutationVariables = {
    input: CreateTemplateInput;
};
export type CreateTemplateMutationResponse = {
    readonly createTemplate: {
        readonly survey: {
            readonly id: string;
            readonly mongoId: string | null;
            readonly name: string | null;
            readonly isDraft: boolean | null;
            readonly isTemplate: boolean | null;
        } | null;
    } | null;
};
export type CreateTemplateMutation = {
    readonly response: CreateTemplateMutationResponse;
    readonly variables: CreateTemplateMutationVariables;
};



/*
mutation CreateTemplateMutation(
  $input: CreateTemplateInput!
) {
  createTemplate(input: $input) {
    survey {
      id
      mongoId
      name
      isDraft
      isTemplate
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateTemplatePayload",
    "kind": "LinkedField",
    "name": "createTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Survey",
        "kind": "LinkedField",
        "name": "survey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mongoId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDraft",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isTemplate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTemplateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTemplateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a9a943c370e070d4f9392cc00dd948e3",
    "id": null,
    "metadata": {},
    "name": "CreateTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTemplateMutation(\n  $input: CreateTemplateInput!\n) {\n  createTemplate(input: $input) {\n    survey {\n      id\n      mongoId\n      name\n      isDraft\n      isTemplate\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f81939b546abd1cc35d05b0892867a4a';
export default node;
