
import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { UpdateUserMonthlyEmailMutation } from '../__generated__/UpdateUserMonthlyEmailMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation UpdateUserMonthlyEmailMutation(
    $input: UpdateUserMonthlyEmailInput!
  ) {
    updateUserMonthlyEmail(input: $input) {
      user {
        id
        monthlyEmail
      }
    }
  }
`;


export const useUpdateUserMonthlyEmailMutation = ():[(config: UseMutationConfig<UpdateUserMonthlyEmailMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<UpdateUserMonthlyEmailMutation>(mutation);
  return [commit, isInFlight];
};




// import {commitMutation, graphql} from 'react-relay';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation UpdateUserMonthlyEmailMutation(
//     $input: UpdateUserMonthlyEmailInput!
//   ) {
//     updateUserMonthlyEmail(input: $input) {
//       user {
//         id
//         monthlyEmail
//       }
//     }
//   }
// `;

// function commit({userId, monthlyEmail}) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           userId,
//           monthlyEmail,
//         },
//       },
//     },
//   );
// }

// export default {commit};
