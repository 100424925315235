/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateDeviceInput = {
    accountId?: string | null | undefined;
    name: string;
    location?: string | null | undefined;
    tags?: Array<string | null> | null | undefined;
    deviceType?: string | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type CreateDeviceMutationVariables = {
    input: CreateDeviceInput;
};
export type CreateDeviceMutationResponse = {
    readonly createDevice: {
        readonly device: {
            readonly id: string;
            readonly mongoId: string | null;
            readonly name: string | null;
            readonly collectVersion: string | null;
            readonly expectActive: boolean | null;
            readonly lastFetched: string | null;
            readonly lastFetchedCollectVersion: string | null;
            readonly location: string | null;
            readonly config: {
                readonly activityTimeout: number | null;
                readonly hardReset: number | null;
                readonly language: string | null;
                readonly languagesToShow: ReadonlyArray<string | null> | null;
                readonly startScreenText: string | null;
                readonly demo: boolean | null;
                readonly endMessageTime: number | null;
                readonly alertOnPageClose: boolean | null;
                readonly reverseSmileys: boolean | null;
            } | null;
        } | null;
    } | null;
};
export type CreateDeviceMutation = {
    readonly response: CreateDeviceMutationResponse;
    readonly variables: CreateDeviceMutationVariables;
};



/*
mutation CreateDeviceMutation(
  $input: CreateDeviceInput!
) {
  createDevice(input: $input) {
    device {
      id
      mongoId
      name
      collectVersion
      expectActive
      lastFetched
      lastFetchedCollectVersion
      location
      config {
        activityTimeout
        hardReset
        language
        languagesToShow
        startScreenText
        demo
        endMessageTime
        alertOnPageClose
        reverseSmileys
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDevicePayload",
    "kind": "LinkedField",
    "name": "createDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mongoId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expectActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastFetched",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastFetchedCollectVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceConfig",
            "kind": "LinkedField",
            "name": "config",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "activityTimeout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hardReset",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "language",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "languagesToShow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startScreenText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "demo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endMessageTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alertOnPageClose",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reverseSmileys",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateDeviceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateDeviceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3553da1e33b724d2848b1071eddd36d4",
    "id": null,
    "metadata": {},
    "name": "CreateDeviceMutation",
    "operationKind": "mutation",
    "text": "mutation CreateDeviceMutation(\n  $input: CreateDeviceInput!\n) {\n  createDevice(input: $input) {\n    device {\n      id\n      mongoId\n      name\n      collectVersion\n      expectActive\n      lastFetched\n      lastFetchedCollectVersion\n      location\n      config {\n        activityTimeout\n        hardReset\n        language\n        languagesToShow\n        startScreenText\n        demo\n        endMessageTime\n        alertOnPageClose\n        reverseSmileys\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '33105696e72872f395a7fc9793323187';
export default node;
