/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateDeviceConfigInput = {
    deviceId: string;
    config?: DeviceConfigInput | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type DeviceConfigInput = {
    activityTimeout?: number | null | undefined;
    hardReset?: number | null | undefined;
    language?: string | null | undefined;
    languagesToShow?: Array<string | null> | null | undefined;
    startScreenText?: string | null | undefined;
    demo?: boolean | null | undefined;
    endMessageTime?: number | null | undefined;
    alertOnPageClose?: boolean | null | undefined;
    reverseSmileys?: boolean | null | undefined;
    cooldownTime?: number | null | undefined;
    endMessageText?: string | null | undefined;
    endMessageUrl?: string | null | undefined;
};
export type UpdateDeviceConfigMutationVariables = {
    input: UpdateDeviceConfigInput;
};
export type UpdateDeviceConfigMutationResponse = {
    readonly updateDeviceConfig: {
        readonly device: {
            readonly id: string;
            readonly config: {
                readonly activityTimeout: number | null;
                readonly hardReset: number | null;
                readonly language: string | null;
                readonly languagesToShow: ReadonlyArray<string | null> | null;
                readonly startScreenText: string | null;
                readonly demo: boolean | null;
                readonly endMessageTime: number | null;
                readonly alertOnPageClose: boolean | null;
                readonly reverseSmileys: boolean | null;
            } | null;
        } | null;
    } | null;
};
export type UpdateDeviceConfigMutation = {
    readonly response: UpdateDeviceConfigMutationResponse;
    readonly variables: UpdateDeviceConfigMutationVariables;
};



/*
mutation UpdateDeviceConfigMutation(
  $input: UpdateDeviceConfigInput!
) {
  updateDeviceConfig(input: $input) {
    device {
      id
      config {
        activityTimeout
        hardReset
        language
        languagesToShow
        startScreenText
        demo
        endMessageTime
        alertOnPageClose
        reverseSmileys
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDeviceConfigPayload",
    "kind": "LinkedField",
    "name": "updateDeviceConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceConfig",
            "kind": "LinkedField",
            "name": "config",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "activityTimeout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hardReset",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "language",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "languagesToShow",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startScreenText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "demo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endMessageTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alertOnPageClose",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reverseSmileys",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDeviceConfigMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateDeviceConfigMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5d6c1a31888feb45149db244e2ef4967",
    "id": null,
    "metadata": {},
    "name": "UpdateDeviceConfigMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDeviceConfigMutation(\n  $input: UpdateDeviceConfigInput!\n) {\n  updateDeviceConfig(input: $input) {\n    device {\n      id\n      config {\n        activityTimeout\n        hardReset\n        language\n        languagesToShow\n        startScreenText\n        demo\n        endMessageTime\n        alertOnPageClose\n        reverseSmileys\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '898b7b370f825d2e80eb892e55fe94c0';
export default node;
