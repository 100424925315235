/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AccountQueryVariables = {
    accountId?: string | null | undefined;
};
export type AccountQueryResponse = {
    readonly accountById: {
        readonly id: string;
        readonly mongoId: string | null;
        readonly name: string | null;
        readonly identifier: string | null;
        readonly email: string | null;
        readonly language: string | null;
        readonly active: boolean | null;
    } | null;
    readonly accounts: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly mongoId: string | null;
                readonly name: string | null;
            } | null;
        } | null> | null;
    } | null;
};
export type AccountQuery = {
    readonly response: AccountQueryResponse;
    readonly variables: AccountQueryVariables;
};



/*
query AccountQuery(
  $accountId: ID
) {
  accountById(accountId: $accountId) {
    id
    mongoId
    name
    identifier
    email
    language
    active
  }
  accounts {
    edges {
      node {
        id
        mongoId
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mongoId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      }
    ],
    "concreteType": "Account",
    "kind": "LinkedField",
    "name": "accountById",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "identifier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "language",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountConnection",
    "kind": "LinkedField",
    "name": "accounts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "0d5400f53604c82300e7db843fe1329f",
    "id": null,
    "metadata": {},
    "name": "AccountQuery",
    "operationKind": "query",
    "text": "query AccountQuery(\n  $accountId: ID\n) {\n  accountById(accountId: $accountId) {\n    id\n    mongoId\n    name\n    identifier\n    email\n    language\n    active\n  }\n  accounts {\n    edges {\n      node {\n        id\n        mongoId\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c510fd587d584836788cec97af4f7611';
export default node;
