import * as React from 'react';
import Box from '@mui/material/Box';
// import MenuItem from '@mui/material/MenuItem';
// import Typography from '@mui/material/Typography';
import { primaryColor } from '../../theme';

// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';

// import { useLocale } from '../../contexts';
// import {
//   LOCALES,
//   COPYRIGHT_YEAR,
//   COMPANY_NAME,
//   PRIVACY_POLICY_URL,
// } from '../../config';

// const localeSelectStyles = {
//   color: 'white',
//   svg: {
//     color: 'inherit',
//   },
//   '.MuiInput-input': {
//     '&:focus': {
//       backgroundColor: 'transparent',
//     },
//     '&:hover': {
//       backgroundColor: 'transparent',
//     },
//   },
// };

export function Footer() {

  // const { intl, locale, setLocale } = useLocale();

  // function handleLocaleChange(e: any) {
  //   localStorage.setItem('locale', e.target.value);
  //   // setLocale(e.target.value);
  // }

  return (
    <Box sx={{ 
        minHeight: '75px',
        padding: '8px 24px',
        backgroundColor: primaryColor,
      }}>
      <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          textAlign: 'left',
          'button + button': {
            ml: 1,
          },
        }}>

      </Box>
    </Box>
  );
}