import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { GenerateTokenMutation } from '../__generated__/GenerateTokenMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation GenerateTokenMutation(
    $input: GenerateTokenInput!
  ) {
    generateToken(input: $input) {
      token
    }
  }
`;


export const useGenerateTokenMutation = ():[(config: UseMutationConfig<GenerateTokenMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<GenerateTokenMutation>(mutation);
  return [commit, isInFlight];
};



// import {commitMutation, graphql} from 'react-relay';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation GenerateTokenMutation(
//     $input: GenerateTokenInput!
//   ) {
    // generateToken(input: $input) {
    //   token
    // }
//   }
// `;

// function commit({id, role}, callback) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           id,
//           role,
//         },
//       },
//       onCompleted: (res) => {
//         callback(null, res.generateToken.token);
//       },
//       onError: (err) => {
//         callback(err);
//       },
//     },
//   );
// }

// export default {commit};
