import graphql from 'babel-plugin-relay/macro';



export const UsersByAccountQuery = graphql`
query UsersByAccountQuery(
    $accountId: ID
  ) {

  usersByAccount(
    accountId: $accountId
  ) {
    edges {
      node {
        id
        name
        firstName
        lastName
        email
        language
        weeklyEmail
        monthlyEmail
        advancedMode
        accessToDevices {
          id
        }
        accessToReports {
          id
        }
      }
    }
  }

}
`;


