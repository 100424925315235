
import {
  usePreloadedQuery,
} from "react-relay/hooks";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { DateTime } from 'luxon';
import {
  MOKI_URL,
  OFFLINE_TIMEOUT,
} from '../../../../config';


const tdSx = {
  padding: '0.5em',
};

export default function DeviceListData(props: any) {

  const {
    queryRef,
    query,
    accountName,
    accountNameOrderBy,
  } = props;

  const preloaded: any = usePreloadedQuery(query, queryRef);

  /**
   * Get template status
   * @returns 
   */
  function getStatus(device: any) {
    if (!device || !device.status) {
      return 'Unknown';
    }
    return device.status;
  }

  const deviceList = preloaded.adminDevices?.edges.map((edge: any) => edge ? edge.node : null).filter(Boolean);

  const devices = !accountName ? deviceList : deviceList.reduce((a: any, c: any) => {
    if (((c && c.accountData) ? (c.accountData.name || '') : '').toLowerCase().indexOf(accountName.toLowerCase()) !== -1) {
      a.push(c);
    }
    return a;
  }, []);

  if (accountNameOrderBy) {
    devices.sort(function (a: any, b: any) {

      var textA = ((a && a.accountData) ? (a.accountData.name || '') : '').toLowerCase();
      var textB = ((b && b.accountData) ? (b.accountData.name || '') : '').toLowerCase();
      if (accountNameOrderBy === 1) {
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      } else {
        return (textA < textB) ? 1 : (textA > textB) ? -1 : 0;
      }
    });
  }

  /**
   * Checks whether the device is considered offline
   */
  function getIsOffline(device: any) {
    if (!device || !device.lastFetched) {
      return false;
    }
    const dt = DateTime.now();
    const lastFetched = DateTime.fromISO(device.lastFetched);
    const diff = dt.diff(lastFetched, 'minutes').toObject();
    if (!diff.minutes) {
      return false;
    }
    return diff.minutes > OFFLINE_TIMEOUT;
  }

  /**
   * 
   */
  return <TableBody>
    {(devices || []).map((device: any) => {

      const isOffline = getIsOffline(device);

      return <TableRow
        sx={{
          ' td': {
            backgroundColor: isOffline ? '#fee0e0' : 'transparent',
          }
        }}
        key={device.mongoId}>

        <TableCell sx={tdSx}> {device.mongoId} </TableCell>
        <TableCell sx={tdSx}> {device.supportId} </TableCell>
        <TableCell sx={tdSx}> {device.deviceType} </TableCell>
        <TableCell sx={tdSx}> {device ? device.name : ''} </TableCell>
        <TableCell sx={tdSx}> {(device && device.accountData) ? device.accountData.name : ''} </TableCell>
        <TableCell sx={tdSx}> {getStatus(device.status)} </TableCell>
        <TableCell sx={tdSx}> {device.isNewCollect ? '✓' : ''} </TableCell>
        <TableCell sx={tdSx}> {device.expectActive ? '✓' : ''} </TableCell>
        <TableCell sx={tdSx}> {device.lastFetched} </TableCell>
        <TableCell sx={tdSx}> {device.createdAt} </TableCell>
        <TableCell sx={tdSx}>
          {device.udid ?
            <a
              rel="noreferrer"
              target="_blank"
              href={`${MOKI_URL}${device.udid}`}>
              {device.udid}
            </a> : null}
        </TableCell>


      </TableRow>;
    }
    )}
  </TableBody>

}
