import { RelayRoot } from './components';
import { Auth0ProviderWithNavigate } from './components';
import { AuthWrapper } from './components';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  responsiveFontSizes,
} from '@mui/material/styles';
import tntTheme from './theme/tntTheme';
import { Main } from './components/Main';
// import { AppLoader } from './views';
import { SnackbarProvider } from 'notistack';


// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }
//
// import { SnackbarProvider } from 'notistack';
// 
// <SnackbarProvider anchorOrigin={{horizontal: 'right', vertical: 'top'}} variant="warning" maxSnack={3}>
// </SnackbarProvider> 



function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={responsiveFontSizes(createTheme(tntTheme))}>
        <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }} variant="warning" maxSnack={3}>
          <Auth0ProviderWithNavigate>
            <AuthWrapper render={(token: string) => (
              <RelayRoot token={token}>
                <Main />
              </RelayRoot>
            )} />
          </Auth0ProviderWithNavigate>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
