import {
  usePreloadedQuery,
} from "react-relay/hooks";
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  useCreateTemplateMutation,
  useDeleteSurveyMutation,
} from '../../../../mutations';



const h1Styles = {
  fontFamily: 'sans-serif',
};

const buttonSx = {
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  marginBottom: '0.25em',
  fontSize: '0.75em',
  padding: '1px 6px',

};

const tdSx = {
  padding: '0.5em',
};

export default function SurveyListData(props: any) {

  // console.log('ss list init', Date.now())

  // const preloaded: any = useLazyLoadQuery(
  //   SurveysQuery
  //   , {}
  //   );

  const { queryRef, query } = props;
  const preloaded: any = usePreloadedQuery(query, queryRef);

  // const { data } = useFragment(
  //     graphql`some_fragment`,
  //     loaded
  // );
  // console.log('loaded', loaded);

  // console.log('preloaded', preloaded)


  const [commitCreateTemplate] = useCreateTemplateMutation();
  const [commitDeleteSurvey] = useDeleteSurveyMutation();

  // const navigate = useNavigate();

  // useEffect(() => {
  //   navigate('/surveys')
  // }, [ history]);


  /**
   * Get template status
   * @returns 
   */
  function getTemplateStatus(survey: any) {
    return survey.isTemplate ? 'Template' : (
      !!survey.isDraft ? 'Draft' : 'Survey'
    );
  }

  /**
   * Handle create template
   */
  function handleCreateTemplate(survey: any) {

    commitCreateTemplate({
      variables: {
        input: {
          id: String(survey?.id),
        },
      },
      updater: (store: any, payload: any) => {

      }
    });

  }

  /**
   * Handle delete survey
   */
  function handleDelete(survey: any) {

    commitDeleteSurvey({
      variables: {
        input: {
          id: String(survey?.id),
        },
      },
      updater: (store: any, payload: any) => {

      }
    });

  }

  const surveys = preloaded.adminSurveys.edges.map((edge: any) => edge ? edge.node : null).filter(Boolean);

  return <div>

    <div>
      <h1 style={h1Styles}> Surveys </h1>
    </div>

    <Table
    // selectable={false}

    >
      <TableHead
      // displaySelectAll={false}
      >
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody
      // displayRowCheckbox={false}
      >
        {(surveys || []).map((survey: any) =>
          <TableRow
            key={survey.mongoId}>
            <TableCell sx={tdSx}> {survey.mongoId} </TableCell>
            <TableCell sx={tdSx}> {survey ? survey.name : ''} </TableCell>
            <TableCell sx={tdSx}>{
              getTemplateStatus(survey)
            }</TableCell>
            <TableCell sx={tdSx}>
              <Button
                variant="outlined"
                size="small"
                color="warning"
                sx={buttonSx}
                title="Create template"
                onClick={() => handleCreateTemplate(survey)}
              >
                Create template
              </Button>

              <Button
                variant="outlined"
                size="small"
                color="error"
                sx={buttonSx}
                title="Delete survey with questions and answers"
                onClick={() => handleDelete(survey)}
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>

  </div>

}
