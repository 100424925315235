
import {
  usePreloadedQuery,
} from "react-relay/hooks";
import {
  AccountListItem,
} from '../';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';



export default function AccountData(props: any) {

  const { query, queryRef } = props;

  const preloaded: any = usePreloadedQuery(query, queryRef);

  let account = preloaded.accountById;
  let accountList = preloaded.accounts;

  return (
    <Grid
      sx={{
        width: '100%',
        maxWidth: '100%',
        position: 'relative',
      }}
      container
      flexDirection="column">

      <Grid
        sx={{
          width: '100%',
          maxWidth: '100%',
          position: 'relative',
        }}
        item>
        <Grid
          sx={{
            width: '100%',
            mb: 4,
          }}
          container
          justifyContent="space-between"
          flexDirection="row"
        >

          <Grid item>
            <Typography variant="h1">
              Account Details
            </Typography>
          </Grid>

        </Grid>
      </Grid>

      <Grid
        sx={{
          width: '100%',
          maxWidth: '100%',
          position: 'relative',
        }}
        item>
        <Grid container
          sx={{
            maxWidth: '100%',
            overflow: 'hidden',
          }}>
          <Grid
            sx={{
              maxWidth: '100%',
            }}
            item>

            <div key={account.id}>
              <AccountListItem
                account={account}
                accountList={accountList}
              />
            </div>

          </Grid>
        </Grid>
      </Grid>

    </Grid>

  );
}
