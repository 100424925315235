
import {
  useEffect,
} from 'react';
import {
  useQueryLoader,
} from "react-relay/hooks";
import AccountData from './components/AccountData/AccountData';
import { AppLoader } from '../AppLoader/AppLoader';
import { AccountQuery } from '../../queries';
import { useMatch } from 'react-router-dom';
import { DashboardLayout } from '../DashboardLayout/DashboardLayout';


/**
 * Account
 * @param props 
 * @returns 
 */
export default function Account(props: any) {

  const query = AccountQuery;
  const match: any = useMatch(('/account/:accountId' || ''));
  const params = match ? (match?.params || {}) : {};
  const { accountId } = params;

  const [
    queryRef,
    loadQuery,
  ] = useQueryLoader(query);

  /**
   * Use effect
   */
  useEffect(() => {

    if (accountId) {
      loadQuery(
        {
          accountId: accountId,
        },
        /// export type PreloadFetchPolicy = 'store-or-network' | 'store-and-network' | 'network-only';
        { fetchPolicy: 'store-or-network' },
      );
    }

    // eslint-disable-next-line
  }, [loadQuery, JSON.stringify(params)])



  return (<DashboardLayout>
    {!!queryRef ?
      <AccountData query={query} queryRef={queryRef} />
      : <AppLoader />}
  </DashboardLayout>);
}
