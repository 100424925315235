
import {
  SurveysQuery,
} from '../../queries';
import {
  useQueryLoader,
} from "react-relay/hooks";
import { useEffect } from 'react';
import { AppLoader } from '../AppLoader/AppLoader';
import SurveyListData from './components/SurveyListData/SurveyListData';
import { DashboardLayout } from '../DashboardLayout/DashboardLayout';




export default function SurveyList(props: any) {

  const query = SurveysQuery;

  const [
    queryRef,
    loadQuery,
  ] = useQueryLoader(query);


  /**
   * Use effect
   */
  useEffect(() => {
    loadQuery(
      {
        count: 20,
      },
      /// export type PreloadFetchPolicy = 'store-or-network' | 'store-and-network' | 'network-only';
      { fetchPolicy: 'store-or-network' },
    );
    // eslint-disable-next-line
  }, [loadQuery])

  return <DashboardLayout>

    {!!queryRef ?
      <SurveyListData query={query} queryRef={queryRef} />
      : <AppLoader />}

  </DashboardLayout>

}
