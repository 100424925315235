
import {
  DevicesQuery,
} from '../../queries';
import {
  useQueryLoader
} from "react-relay";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeviceListData from './components/DeviceListData/DeviceListData';
import {
  useEffect,
  useState,
} from 'react';
import {
  AppLoader,
} from '../AppLoader/AppLoader';
import FilterInput from './components/FilterInput/FilterInput';
import OrderBy from './components/OrderBy/OrderBy';
import {
  DashboardLayout,
} from '../DashboardLayout/DashboardLayout';
import OrderByDropdown from './components/OrderByDropdown/OrderByDropdown';
import { DEVICE_TYPE_TRANSLATIONS } from '../../config';


const filterInputStyles = {
  fontSize: '0.65rem',
  padding: '8px',
  lineHeight: 1,
  label: {
    fontSize: '0.65rem',
    padding: '8px',
    lineHeight: 1,
  },
  ' .MuiInputBase-input': {
    padding: '8px',
    lineHeight: 1,
  }
};

const orderByDropdownStyles = {
  fontSize: '0.65rem',
  padding: '8px',
  lineHeight: 1,
  ' .MuiMenuItem-root': {
    fontSize: '0.65rem',
    padding: '8px',
    lineHeight: 1,
  },
  ' .MuiSelect-select': {
    fontSize: '0.65rem',
    padding: '8px',
    lineHeight: 1,
  },
  ' .MuiButtonBase-root': {
    fontSize: '0.65rem',
    padding: '8px',
  },
};

const h1Styles = {
  fontFamily: 'sans-serif',
};

const statuses: { value: string, label: string }[] = [
  { value: '', label: '<reset>' },
  { value: 'active', label: 'Active' },
  { value: 'deleted', label: 'Deleted' },
];


const isNewCollectValues: { value: boolean | null, label: string }[] = [
  { value: null, label: '<reset>' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];



export default function DeviceList(props: any) {

  const [orderBy, setOrderBy] = useState<any>({
    createdAt: null,
    name: null,
    status: null,
    lastFetched: null,
    expectActive: null,
    isNewCollect: null,

  });

  const [filter, setFilter] = useState<any>({
    mongoId: "",
    udid: "",
    supportId: "",
    name: "",
    deviceType: "",
    status: "",
    isNewCollect: null,
  });

  const [accountName, setAccountName] = useState<any>('');
  const [accountNameOrderBy, setAccountNameOrderBy] = useState<any>(null);

  const query = DevicesQuery;

  const [
    queryRef,
    loadQuery,
    // disposeQuery
  ] = useQueryLoader(query);



  /**
   * Use effect
   */
  useEffect(() => {
    // const runOnChange = setTimeout(() => {
    loadQuery(
      {
        count: 20,
        filter: filter,
        orderBy: orderBy,
      },
      // export type PreloadFetchPolicy = 'store-or-network' | 'store-and-network' | 'network-only';
      { fetchPolicy: 'network-only' },
    );
    // }, (1000))

    // return () => clearTimeout(runOnChange)
    // eslint-disable-next-line
  }, [loadQuery, JSON.stringify(orderBy), JSON.stringify(filter)])



  /**
   * Update filter
   */
  function updateFilter(newFilter: any): any {
    // console.log('newFilter', newFilter);
    // const base = Object.assign({}, filter);
    // Object.keys(newFilter).forEach((key) => base[key] = newFilter[key]);

    if (newFilter.accountName) {
      setAccountName(newFilter.accountName);
      return;
    }

    const base = Object.assign({}, filter, newFilter);
    // console.log('filter ', base);
    setFilter(base);
    // setTrigger(Date.now());
  }



  /**
   * Order by
   */
  function updateOrderBy(newOrderBy: any): any {
    // console.log('newOrderBy', newOrderBy);
    // const base = Object.assign({}, orderBy);
    // Object.keys(newOrderBy).forEach((key) => base[key] = newOrderBy[key]);

    if (newOrderBy.accountNameOrderBy) {
      setAccountNameOrderBy(newOrderBy.accountNameOrderBy);
      return;
    }

    const base = Object.assign({}, orderBy, newOrderBy);
    // console.log('orderBy ', base);
    setOrderBy(base);
    // setTrigger(Date.now());
  }



  const filterDebounceTime = 1250;

  const orderByDebounceTime = 500;

  ///
  ///
  ///
  return <DashboardLayout>
    <div>
      <h1 style={h1Styles}> Devices </h1>
    </div>
    {!!queryRef ?
      <Table
        sx={{
          ' .MuiTableCell-root': {
            lineHeight: 1,
            fontSize: '0.65rem',
          }
        }}
      // selectable={false}
      >
        <TableHead
        // displaySelectAll={false}
        >

          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Support ID</TableCell>
            <TableCell>Device Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Account Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>New Collect App</TableCell>
            <TableCell>Expect Active</TableCell>
            <TableCell>Last fetched</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>UDID</TableCell>
          </TableRow>

          <TableRow>
            <TableCell> {/* id */} </TableCell>
            <TableCell> {/* Support ID */} </TableCell>
            <TableCell> {/* Device Type */} </TableCell>
            <TableCell>
              <OrderBy
                key="order-by-name"
                value={orderBy.name}
                onChange={(event: any) => updateOrderBy({ name: event })}
                debounceTime={orderByDebounceTime}
              />
              {/* order by name */}
            </TableCell>
            <TableCell>
              <OrderBy
                key="order-by-account-name"
                value={accountNameOrderBy}
                onChange={(event: any) => updateOrderBy({ accountNameOrderBy: event })}
                debounceTime={orderByDebounceTime}
              />
            </TableCell>
            <TableCell>
              <OrderBy
                key="order-by-status"
                value={orderBy.status}
                onChange={(event: any) => updateOrderBy({ status: event })}
                debounceTime={orderByDebounceTime}
              />
              {/* order by Status */}
            </TableCell>
            <TableCell>
              <OrderBy
                key="order-by-is-new-collect"
                value={orderBy.isNewCollect}
                onChange={(event: any) => updateOrderBy({ isNewCollect: event })}
                debounceTime={orderByDebounceTime}
              />
              {/* order by isNewCollect */}
            </TableCell>
            <TableCell>
              <OrderBy
                key="order-by-expect-active"
                value={orderBy.expectActive}
                onChange={(event: any) => updateOrderBy({ expectActive: event })}
                debounceTime={orderByDebounceTime}
              />
              {/* order expect active */}
            </TableCell>
            <TableCell>
              <OrderBy
                key="order-by-last-fetched"
                value={orderBy.lastFetched}
                onChange={(event: any) => updateOrderBy({ lastFetched: event })}
                debounceTime={orderByDebounceTime}
              />
              {/* order BYLast fetched */}
            </TableCell>
            <TableCell>
              <OrderBy
                key="order-by-created-at"
                value={orderBy.createdAt}
                onChange={(event: any) => updateOrderBy({ createdAt: event })}
                debounceTime={orderByDebounceTime}
              />
              {/* order BY created at */}
            </TableCell>
            <TableCell> {/* UDID */} </TableCell>
          </TableRow>

          {/* -------------------------------------------- */}

          <TableRow>

            <TableCell>
              <FilterInput
                sx={{ ...filterInputStyles }}
                key="filter-id"
                label={"ID"}
                value={filter.mongoId}
                onChange={(event: any) => updateFilter({ mongoId: event })}
                debounceTime={filterDebounceTime}
              />
            </TableCell>

            <TableCell>
              <FilterInput
                sx={{ ...filterInputStyles }}
                key="filter-support-id"
                label={"Support ID"}
                value={filter.supportId}
                onChange={(event: any) => updateFilter({ supportId: event })}
                debounceTime={filterDebounceTime}
              />
            </TableCell>

            <TableCell>
              <OrderByDropdown
                sx={{ ...orderByDropdownStyles }}
                key="filter-device-type"
                options={DEVICE_TYPE_TRANSLATIONS}
                value={filter.deviceType}
                onChange={(event: any) => updateFilter({ deviceType: event })}
                debounceTime={filterDebounceTime}
              />
            </TableCell>

            <TableCell>
              <FilterInput
                sx={{ ...filterInputStyles }}
                key="filter-name"
                label={"Name"}
                value={filter.name}
                onChange={(event: any) => updateFilter({ name: event })}
                debounceTime={filterDebounceTime}
              />
            </TableCell>

            <TableCell>
              <FilterInput
                sx={{ ...filterInputStyles }}
                key="filter-account-name"
                label={"Account Name"}
                value={accountName}
                onChange={(event: any) => updateFilter({ accountName: event })}
                debounceTime={filterDebounceTime}
              />
            </TableCell>

            <TableCell>
              <OrderByDropdown
                sx={{ ...orderByDropdownStyles }}
                key="filter-status"
                options={statuses}
                value={filter.status}
                onChange={(event: any) => updateFilter({ status: event })}
                debounceTime={filterDebounceTime}
              />
            </TableCell>

            <TableCell>
              <OrderByDropdown
                sx={{ ...orderByDropdownStyles }}
                key="filter-is-new-collect"
                options={isNewCollectValues}
                value={filter.isNewCollect}
                onChange={(event: any) => updateFilter({ isNewCollect: event })}
                debounceTime={filterDebounceTime}
              />
              {/* isNewCollect */}
            </TableCell>

            <TableCell> {/* Expect Active */} </TableCell>

            <TableCell> {/* Last fetched */} </TableCell>

            <TableCell> {/* Created */} </TableCell>

            <TableCell>
              <FilterInput
                sx={{ ...filterInputStyles }}
                key="filter-udid"
                label={"UDID"}
                value={filter.udid}
                onChange={(event: any) => updateFilter({ udid: event })}
                debounceTime={filterDebounceTime}
              />
            </TableCell>

          </TableRow>

        </TableHead>

        <DeviceListData
          accountName={accountName}
          accountNameOrderBy={accountNameOrderBy}
          query={query}
          queryRef={queryRef} />

      </Table>
      : <AppLoader />}
  </DashboardLayout>

}
