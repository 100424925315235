import {
  useState,
} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import {
  useTransferDeviceMutation
} from '../../../../mutations';
import { useNotification } from '../../../../hooks/useNotification';
import { MenuItem, Select, Typography } from '@mui/material';



/**
 * 
 * @param props 
 * @returns 
 */
export function TransferDeviceDialog(props: any) {

  const { open, onClose } = props;

  const device = props.device || {};
  const accountList: any = props.accountList || [];
  const accounts: any[] = accountList?.edges?.map((edge: any) => edge ? edge.node : null).filter(Boolean)
    .sort(function (a: any, b: any) {
      var textA = (a.name || '').toLowerCase();
      var textB = (b.name || '').toLowerCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      // reverse 
      // return (textA < textB) ? 1 : (textA > textB) ? -1 : 0;

    });

  const [accountId, setAccountId] = useState('');

  const [commitTransferDeviceMutation] = useTransferDeviceMutation();

  const { success, handleError } = useNotification();

  /**
   * Set account id
   * @param event 
   */
  function updateAccountId(event: any) {
    setAccountId(event.target.value);
  }

  /**
   * Save 
   * @returns 
   */
  function save() {

    if (!accountId) {
      return;
    }

    // TransferDeviceMutation.commit({
    //   deviceId: device.id,
    //   collectVersion: version,
    // });

    commitTransferDeviceMutation({
      variables: {
        input: {
          deviceId: device.id,
          accountId,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        success('The device has been successfully transferred.');
      },
      onError: (error) => {
        handleError(error);
      },
    });

    onClose();
  }

  function cancel() {
    setAccountId('');
    onClose();
  }



  return (
    <Dialog
      title="Transfer device to another account"
      open={open}
      onClose={onClose}
    >

      <DialogContent>

        <Typography
          sx={{
            width: '100%',
            mb: 2,
          }}
          variant="h4">
          Transfer device to another account
        </Typography>

        <Typography
          sx={{
            width: '100%',
            mb: 4,
          }}
          variant="body1">
          The following device will be transferred: <br />
          <strong>{device.name}</strong><br />
          ID {device.mongoId}
        </Typography>

        <Select
          // sx={{ ...styles }}
          multiple={false}
          value={accountId}
          onChange={updateAccountId}
        >
          {
            accounts.map((v: any) =>
              <MenuItem
                key={`device-transfer-dropdown-${v.id}`}
                value={v.id}>
                <strong>{v.name}</strong> <span>&nbsp;({v.mongoId})</span>
              </MenuItem>
            )
          }
        </Select>
        {/* <TextField
          sx={{
            width: '100%',
          }}
          label="Target account"
          value={accountId}
          onChange={updateAccountId}
        /> */}

      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          disableElevation={true}
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={save}
        >
          Transfer
        </Button>
      </DialogActions>

    </Dialog>
  );

}
