import {
  useState,
  ChangeEvent,
} from 'react';
import {
  usePreloadedQuery,
} from "react-relay/hooks";
import {
  grey400,
  grey500,
} from '../../../../theme';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth0 } from "@auth0/auth0-react";
import Fab from '@mui/material/Fab';
import {
  CreateAccountDialog,
  AccountListItem,
} from '../';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';


const styles = {
  wrapper: {
    margin: '0 0 100px',
  },
  filtersWrapper: {
    marginBottom: 10,
    paddingLeft: 15,
  },
  filtersItemWrapper: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    height: 50,
    marginRight: 30,
  },
  searchIcon: {
    marginRight: 10,
    paddingTop: 4,
  },
  searchInput: {
    hint: {
      color: grey500,
    },
    underline: {
      borderColor: grey400,
    },
  },
  toggleActive: {
    paddingTop: 2,
  },
  toggleActiveLabel: {
    fontSize: 14,
  },
  addAccountButton: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    zIndex: 999,
  },
};


export default function AccountListData(props: any) {

  const { queryRef, query } = props;
  const preloaded: any = usePreloadedQuery(query, queryRef);

  const [searchValue, setSearchValue] = useState<string>('');
  const [showInactiveAccounts, setShowInactiveAccounts] = useState<boolean>(false);
  const [addAccountDialogOpen, setAddAccountDialogOpen] = useState<boolean>(false);

  const { logout } = useAuth0();

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    setSearchValue(event.target.value);
  }

  function handleShowInactiveToggle(event: ChangeEvent<HTMLInputElement>) {
    setShowInactiveAccounts(!!event.target.checked);
  }

  function showAddAccountDialog() {
    setAddAccountDialogOpen(true);
  }

  function hideAddAccountDialog() {
    setAddAccountDialogOpen(false);
  }

  if (!preloaded.accounts) {
    // logout({ returnTo: window.location.origin });

    const returnUrl = window.location.origin;
    logout({
      async openUrl(url) {
        localStorage.removeItem('accessToken');
        window.location.replace(returnUrl);
      }
    });
  }

  let accountNodes = preloaded?.accounts?.edges?.map((edge: any) => edge ? edge.node : null).filter(Boolean);

  // console.log('accountNodes 0', accountNodes)


  if (!showInactiveAccounts) {
    accountNodes = accountNodes.filter((node: any) => node.active);
  }

  if (searchValue) {
    accountNodes = accountNodes.filter((node: any) => {
      const name = node ? (node.name || '') : '';
      const identifier = node.identifier || '';
      const val = searchValue.toLowerCase();
      return (
        name.toLowerCase().indexOf(val) !== -1 ||
        identifier.toLowerCase().indexOf(val) !== -1
      );
    });
  }

  return (
    <Grid
      sx={{
        width: '100%',
        maxWidth: '100%',
        position: 'relative',
      }}
      container
      flexDirection="column">

      <Grid
        sx={{
          width: '100%',
          maxWidth: '100%',
          position: 'relative',
        }}
        item>
        <Grid
          sx={{
            width: '100%',
            mb: 4,
          }}
          container
          justifyContent="space-between"
          flexDirection="row"
        >

          <Grid item>
            <Typography variant="h1">
              Accounts
            </Typography>
          </Grid>

          <Grid item>
            <Fab
              onClick={showAddAccountDialog}
            // sx={styles.addAccountButton}
            >
              <AddIcon />
            </Fab>
            <CreateAccountDialog
              open={addAccountDialogOpen}
              onClose={hideAddAccountDialog}
            />
          </Grid>

        </Grid>
      </Grid>

      <Grid
        sx={{
          width: '100%',
          maxWidth: '100%',
          position: 'relative',
          mb: 4,
        }}
        item>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row">

          <Grid item>
            <TextField
              value={searchValue}
              onChange={handleSearch}
              helperText="Search account name / identifier"
              // ToDo: migrate styles properly
              // helperStyle, underlineStyle,
              sx={{
                ...styles.searchInput.hint,
                ...styles.searchInput.underline
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}

            />
          </Grid>



          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={showInactiveAccounts}
                    onChange={handleShowInactiveToggle}
                  // style={styles.toggleActive}
                  />
                }
                // sx={styles.toggleActiveLabel}
                labelPlacement="end"
                label="Show inactive accounts"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        sx={{
          width: '100%',
          maxWidth: '100%',
          position: 'relative',
        }}
        item>
        <Grid container
          sx={{
            maxWidth: '100%',
            overflow: 'hidden',
          }}>
          <Grid
            sx={{
              maxWidth: '100%',
            }}
            item>
            {accountNodes.map((account: any) =>
              <div key={account.id}>
                <AccountListItem
                  account={account} />
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>

    </Grid>

  );
}
