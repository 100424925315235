
import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { UpdateDeviceCollectVersionMutation } from '../__generated__/UpdateDeviceCollectVersionMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation UpdateDeviceCollectVersionMutation(
    $input: UpdateDeviceCollectVersionInput!
  ) {
      updateDeviceCollectVersion(input: $input) {
        device {
          id
          collectVersion
        }
      }
  }
`;


export const useUpdateDeviceCollectVersionMutation = ():[(config: UseMutationConfig<UpdateDeviceCollectVersionMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<UpdateDeviceCollectVersionMutation>(mutation);
  return [commit, isInFlight];
};






// import {commitMutation, graphql} from 'react-relay';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation UpdateDeviceCollectVersionMutation(
//     $input: UpdateDeviceCollectVersionInput!
//   ) {
//     updateDeviceCollectVersion(input: $input) {
//       device {
//         id
//         collectVersion
//       }
//     }
//   }
// `;

// function commit({deviceId, collectVersion}) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           deviceId,
//           collectVersion,
//         },
//       },
//     },
//   );
// }

// export default {commit};
