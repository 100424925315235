import './AppLoader.css';

export function AppLoader() {
  return (
    <div className="app-loader"> 
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}