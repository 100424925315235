/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteSurveyInput = {
    id: string;
    clientMutationId?: string | null | undefined;
};
export type DeleteSurveyMutationVariables = {
    input: DeleteSurveyInput;
};
export type DeleteSurveyMutationResponse = {
    readonly deleteSurvey: {
        readonly surveyId: string | null;
        readonly questionIds: ReadonlyArray<string | null> | null;
    } | null;
};
export type DeleteSurveyMutation = {
    readonly response: DeleteSurveyMutationResponse;
    readonly variables: DeleteSurveyMutationVariables;
};



/*
mutation DeleteSurveyMutation(
  $input: DeleteSurveyInput!
) {
  deleteSurvey(input: $input) {
    surveyId
    questionIds
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteSurveyPayload",
    "kind": "LinkedField",
    "name": "deleteSurvey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "surveyId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "questionIds",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteSurveyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteSurveyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "479cba7d335134decf46bee1a2e1dad8",
    "id": null,
    "metadata": {},
    "name": "DeleteSurveyMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteSurveyMutation(\n  $input: DeleteSurveyInput!\n) {\n  deleteSurvey(input: $input) {\n    surveyId\n    questionIds\n  }\n}\n"
  }
};
})();
(node as any).hash = '016d30cf626ab1808f0a653196606cd9';
export default node;
