import { Environment, Network, RecordSource, Store } from 'relay-runtime';



export const useEnvironment = (token: string, baseURL: string) => {

  return new Environment({
    network: Network.create(async (params, variables, _cacheConfig) => {

      if (!token) {
        throw new Error(
          'Token is missing.',
        );
      }

      const response = await fetch(`${baseURL}/graphql`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: params.text,
          variables,
        }),
      });

      const json = await response.json();

      if (Array.isArray(json.errors)) {
        console.log('ERROR\n', json.errors);
        throw new Error(
          `Error fetching GraphQL query '${params.name
          }' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
            json.errors,
          )}`,
        );
      }

      return json;
    }),
    store: new Store(new RecordSource(), {
      // This property tells Relay to not immediately clear its cache when the user
      // navigates around the app. Relay will hold onto the specified number of
      // query results, allowing the user to return to recently visited pages
      // and reusing cached data if its available/fresh.
      gcReleaseBufferSize: 10,
    }),
  });

}



// export const useEnvironment = (() => function (token: string, baseURL: string): any {

//   let env: any;

//   if (!env) {
//     env = createEnvironment(token, baseURL);
//   }

//   return env;

// })();


// const createEnvironment = (token: string, baseURL: string) => {

//   return new Environment({
//     network: Network.create(async (params, variables, _cacheConfig) => {

//       if (!token) {
//         throw new Error(
//           'Token is missing.',
//         );
//       }

//       const response = await fetch(`${baseURL}/graphql`, {
//         method: 'POST',
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           query: params.text,
//           variables,
//         }),
//       });

//       const json = await response.json();

//       if (Array.isArray(json.errors)) {
//         console.log('ERROR\n', json.errors);
//         throw new Error(
//           `Error fetching GraphQL query '${params.name
//           }' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
//             json.errors,
//           )}`,
//         );
//       }

//       return json;
//     }),
//     store: new Store(new RecordSource(), {
//       // This property tells Relay to not immediately clear its cache when the user
//       // navigates around the app. Relay will hold onto the specified number of
//       // query results, allowing the user to return to recently visited pages
//       // and reusing cached data if its available/fresh.
//       gcReleaseBufferSize: 10,
//     }),
//   });

// }
