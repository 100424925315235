import { useState } from 'react';
import { grey500 } from '../../../../theme';
import Fab from '@mui/material/Fab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import {
  DeviceListItem,
  CreateDeviceDialog,
} from '../../components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import {
  usePreloadedQuery,
} from "react-relay/hooks";



const styles = {
  card: {
    position: 'relative',
  },
  cardActions: {
    padding: '0px 16px',
  },
  table: {
    tableLayout: 'auto',
  },
  tableBody: {
    overflow: 'visible',
  },
  noDevicesMessage: {
    color: grey500,
    fontStyle: 'italic',
  },
};



export function DeviceList(props: any) {

  const { queryRef, query, account, accountList } = props;

  const preloaded: any = usePreloadedQuery(query, queryRef);
  const devices = preloaded.devicesByAccount;

  // console.log('preloaded');
  // console.log(preloaded);

  const [addDeviceDialogOpen, setAddDeviceDialogOpen] = useState<boolean>(false);

  function showAddDeviceDialog() {
    setAddDeviceDialogOpen(true);
  }

  function hideAddDeviceDialog() {
    setAddDeviceDialogOpen(false);
  }



  const deviceEdges = (devices && devices.edges) || [];
  const deviceNodes = deviceEdges.map((edge: any) => edge ? edge.node : null).filter(Boolean);
  const tags = deviceNodes.reduce((a: any, c: any) => {
    if (c.tags) {
      c.tags.forEach((t: any) => a.indexOf(t) === -1 && a.push(t));
    }
    return a;
  }, []);

  return (
    <Accordion
      sx={{
        border: '1px solid #c4e2e7',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      <AccordionSummary
        sx={{
          marginBottom: '-1px',
          borderBottom: '1px solid #c4e2e7',
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container flexDirection="column">
          <Grid item>
            <Typography variant="h6">
              Devices
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          overflowX: 'scroll',
        }}
      >
        <Grid container flexDirection="column">
          <Grid item>
            <Fab
              onClick={showAddDeviceDialog}
              size="small"
            >
              <AddIcon />
            </Fab>
          </Grid>
          <Grid item>

            {
              deviceNodes.length > 0 ?
                (
                  <Table sx={{
                    ...styles.table,
                  }}>
                    <TableBody sx={{
                      ...styles.tableBody,
                    }}>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Device type</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Last seen</TableCell>
                        <TableCell>Expect online</TableCell>
                        <TableCell>Collect version</TableCell>
                        <TableCell>Time zone</TableCell>
                        <TableCell>Tags</TableCell>
                        <TableCell>Actions</TableCell>
                        <TableCell>Config</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {deviceNodes.map((device: any) =>
                        <DeviceListItem
                          tags={tags}
                          key={device.id}
                          device={device}
                          accountList={accountList}
                        />
                      )}
                    </TableBody>
                  </Table>
                )
                :
                (
                  <div style={styles.noDevicesMessage}>
                    No devices in this account
                  </div>
                )
            }

          </Grid>
        </Grid>
      </AccordionDetails>
      <CreateDeviceDialog
        account={account}
        deviceCount={deviceNodes.length}
        open={addDeviceDialogOpen}
        onClose={hideAddDeviceDialog}
      />
    </Accordion>
  );

}
