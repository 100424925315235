/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AccountsQueryVariables = {};
export type AccountsQueryResponse = {
    readonly accounts: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly mongoId: string | null;
                readonly name: string | null;
                readonly active: boolean | null;
                readonly identifier: string | null;
            } | null;
        } | null> | null;
    } | null;
};
export type AccountsQuery = {
    readonly response: AccountsQueryResponse;
    readonly variables: AccountsQueryVariables;
};



/*
query AccountsQuery {
  accounts {
    edges {
      node {
        id
        mongoId
        name
        active
        identifier
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountConnection",
    "kind": "LinkedField",
    "name": "accounts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mongoId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "active",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "identifier",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9a17478e273cb303bb54373c27f52189",
    "id": null,
    "metadata": {},
    "name": "AccountsQuery",
    "operationKind": "query",
    "text": "query AccountsQuery {\n  accounts {\n    edges {\n      node {\n        id\n        mongoId\n        name\n        active\n        identifier\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '644618b2ef0f3df68e0694807ee46b37';
export default node;
