import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { CreateUserMutation } from '../__generated__/CreateUserMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation CreateUserMutation(
    $input: CreateUserInput!
  ) {
    createUser(input: $input) {
      user {
        id
        mongoId
        email
        name
        advancedMode
        firstName
        lastName
        language
      }
    }
  }
`;


export const useCreateUserMutation = ():[(config: UseMutationConfig<CreateUserMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<CreateUserMutation>(mutation);
  return [commit, isInFlight];
};



// import {commitMutation, graphql} from 'react-relay';
// import {ConnectionHandler} from 'relay-runtime';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation CreateUserMutation(
//     $input: CreateUserInput!
//   ) {
    // createUser(input: $input) {
    //   user {
    //     id
    //     mongoId
    //     email
    //     name
    //     firstName
    //     lastName
    //     language
    //   }
    // }
//   }
// `;

// function commit({accountId, email, firstName, lastName, language}) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           accountId,
//           email,
//           firstName,
//           lastName,
//           language,
//         },
//       },
//       updater: (store) => {
//         const payload = store.getRootField('createUser');
//         const newUser = payload.getLinkedRecord('user');
//         const account = store.get(accountId);
//         const connection = ConnectionHandler.getConnection(
//           account,
//           'UserList_users',
//         );
//         const newEdge = ConnectionHandler.createEdge(
//           store,
//           connection,
//           newUser,
//           'AccountUserEdge',
//         );
//         ConnectionHandler.insertEdgeBefore(connection, newEdge);
//       },
//     },
//   );
// }

// export default {commit};
