import graphql from 'babel-plugin-relay/macro';



export const DevicesQuery = graphql`
  query DevicesQuery(
      $orderBy: AdminDevicesOrderByInputObjectType!,
      $filter: AdminDevicesFilterInputObjectType!,
    ) {

    adminDevices(
      orderBy: $orderBy,
      filter: $filter
    ) {
      edges {
        node {
          id
          mongoId
          udid
          collectVersion
          supportId
          deviceType
          name
          lastFetched
          status
          createdAt
          expectActive
          account
          isNewCollect
          accountData {
            id
            mongoId
            name
          }
        }
      }
    }

  }
`;


