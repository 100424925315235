
import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { DeleteSurveyMutation } from '../__generated__/DeleteSurveyMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation DeleteSurveyMutation(
    $input: DeleteSurveyInput!
  ) {
    deleteSurvey(input: $input) {
      surveyId
      questionIds
    }
  }
`;


export const useDeleteSurveyMutation = ():[(config: UseMutationConfig<DeleteSurveyMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<DeleteSurveyMutation>(mutation);
  return [commit, isInFlight];
};


// function commit({id}: {id: any}) {

//   const [ commitCurrentMutation ] = getCurrentMutation();

//   commitCurrentMutation({
//     variables: {
//       input: {
//         id,
//       }
//     },
//     updater: (store) => {

//       // const payload = store.getRootField('deleteSurvey');
//       // const record = payload.getLinkedRecord('survey');

//       // const rootRecord = store.getRoot();
//       // const connection: any = ConnectionHandler.getConnection(
//       //   rootRecord, //       'client:root',
//       //   'SurveysQuery_adminSurveys',
//       // );

//       // const newEdge = ConnectionHandler.createEdge(
//       //   store,
//       //   connection,
//       //   record,
//       //   'SurveyEdge',
//       // );
//       // ConnectionHandler.insertEdgeBefore(connection, newEdge);
//     },

//     onError: (error: any) => {
//       // handleError(error);
//     },
//   });

// }

// export default {commit};



// import graphql from 'babel-plugin-relay/macro';
// import { useMutation, UseMutationConfig } from 'react-relay';
// import { PopulateReportMutation } from '../__generated__/PopulateReportMutation.graphql';
// import { Disposable } from 'relay-runtime';

// const mutation = graphql`
//   mutation PopulateReportMutation(
//     $input: PopulateReportInput!
//   ) {
    // populateReport(input: $input) {
    //   report {
    //     id
    //   }
    // }
//   }
// `;

// export const usePopulateReportMutation = ():[(config: UseMutationConfig<PopulateReportMutation>) => Disposable, boolean] => {
//   const [commit, isInFlight] = useMutation<PopulateReportMutation>(mutation);
//   return [commit, isInFlight];
// };
