import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { ImpersonateAccountMutation } from '../__generated__/ImpersonateAccountMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation ImpersonateAccountMutation(
    $input: ImpersonateAccountInput!
  ) {
    impersonateAccount(input: $input) {
      url
    }
  }
`;


export const useImpersonateAccountMutation = ():[(config: UseMutationConfig<ImpersonateAccountMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<ImpersonateAccountMutation>(mutation);
  return [commit, isInFlight];
};



// import {commitMutation, graphql} from 'react-relay';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation ImpersonateAccountMutation(
//     $input: ImpersonateAccountInput!
//   ) {
    // impersonateAccount(input: $input) {
    //   url
    // }
//   }
// `;

// function commit({accountId, callbackUrl}, callback) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           accountId,
//           callbackUrl,
//         },
//       },
//       onCompleted: (res) => {
//         callback(null, res.impersonateAccount.url);
//       },
//       onError: (err) => {
//         callback(err);
//       },
//     },
//   );
// }

// export default {commit};
