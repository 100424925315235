
import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AppLoader, AuthError } from '../views';
import { useEffect, useState } from 'react';



export const AuthWrapper: FC<any> = ({ render }: { render: Function }) => {

  const {
    isLoading,
    error,
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
    // getAccessTokenSilently,
    // user,
  } = useAuth0();

  const savedToken: string = localStorage.getItem('accessToken') || '';
  const [token, setToken] = useState<string>(savedToken);

  // const [view, setView] = useState<string>('loading'); // loader, error, warning, render
  /// 
  /// impersonation feature for account owners / users
  /// 
  // const impersonationToken = localStorage.getItem('token');
  // 
  // console.log('');
  // console.log('_______________');
  // console.log('user', user);
  // console.log('error', error);
  // console.log('token', token);
  // console.log('savedToken', token);
  // console.log('isAuthenticated', isAuthenticated);

  useEffect(() => {
    (async () => {

      // if (impersonationToken) {
      //   setToken(impersonationToken);
      //   return;
      // }

      let rawToken: string = '';

      // if (isAuthenticated) {

      try {
        const claims = await getIdTokenClaims();

        // console.log('claims', claims);
        rawToken = String(claims?.__raw || '');
        if (!rawToken && !!savedToken) {
          rawToken = savedToken;
        }
      } catch (e: any) {
        console.log(`silenced ${e.message}`);
      }

      /// console.log('getTokenSilently', (await getIdTokenClaims()).__raw);
      /// console.log('getAccessTokenSilently', await getAccessTokenSilently() );
      // }

      // console.log('');
      // console.log('_______________');
      // // console.log('isLoading', user);
      // console.log('error', error);
      // console.log('rawToken', rawToken);
      // console.log('isAuthenticated', isAuthenticated);

      // setTimeout(() => {
      // if (!isLoading && isAuthenticated && !error) {
      if (!!rawToken && !error) {
        setToken(rawToken);
        localStorage.setItem('accessToken', rawToken);

        // console.log('isAuthenticated', isAuthenticated);
        // if (doRedirect === null) {
        //   navigate('/accounts');
        //   return;
        // }
      } else {
        loginWithRedirect();
        // console.log('\n ================= loginWithRedirect \n');
        // console.dir(homeMatch);
      }



    })()
    // }, [impersonationToken, isAuthenticated, getIdTokenClaims, getAccessTokenSilently]);
  }, [savedToken, token, setToken, loginWithRedirect, isAuthenticated, isLoading, error, getIdTokenClaims]);

  return <div id="auth-wrapper">
    {error ? <AuthError error={error} /> :
      (!!token ? render(token) : <AppLoader />)
      // (isAuthenticated ? render(token) : <AppLoader />)
    }</div>;

};
