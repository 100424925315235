
import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { UpdateDeviceTimezoneMutation } from '../__generated__/UpdateDeviceTimezoneMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation UpdateDeviceTimezoneMutation(
    $input: UpdateDeviceTimezoneInput!
  ) {
    updateDeviceTimezone(input: $input) {
      device {
        id
        timezone
      }
    }
  }
`;


export const useUpdateDeviceTimezoneMutation = ():[(config: UseMutationConfig<UpdateDeviceTimezoneMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<UpdateDeviceTimezoneMutation>(mutation);
  return [commit, isInFlight];
};






// import {commitMutation, graphql} from 'react-relay';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation UpdateDeviceTimezoneMutation(
//     $input: UpdateDeviceTimezoneInput!
//   ) {
//     updateDeviceTimezone(input: $input) {
//       device {
//         id
//         timezone
//       }
//     }
//   }
// `;

// function commit({deviceId, timezone}) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           deviceId,
//           timezone,
//         },
//       },
//     },
//   );
// }

// export default {commit};
