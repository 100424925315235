/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateUserWeeklyEmailInput = {
    userId: string;
    weeklyEmail: boolean;
    clientMutationId?: string | null | undefined;
};
export type UpdateUserWeeklyEmailMutationVariables = {
    input: UpdateUserWeeklyEmailInput;
};
export type UpdateUserWeeklyEmailMutationResponse = {
    readonly updateUserWeeklyEmail: {
        readonly user: {
            readonly id: string;
            readonly weeklyEmail: boolean | null;
        } | null;
    } | null;
};
export type UpdateUserWeeklyEmailMutation = {
    readonly response: UpdateUserWeeklyEmailMutationResponse;
    readonly variables: UpdateUserWeeklyEmailMutationVariables;
};



/*
mutation UpdateUserWeeklyEmailMutation(
  $input: UpdateUserWeeklyEmailInput!
) {
  updateUserWeeklyEmail(input: $input) {
    user {
      id
      weeklyEmail
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserWeeklyEmailPayload",
    "kind": "LinkedField",
    "name": "updateUserWeeklyEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weeklyEmail",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserWeeklyEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateUserWeeklyEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6efe9dc2a2e6474508ca2e5992825d6c",
    "id": null,
    "metadata": {},
    "name": "UpdateUserWeeklyEmailMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserWeeklyEmailMutation(\n  $input: UpdateUserWeeklyEmailInput!\n) {\n  updateUserWeeklyEmail(input: $input) {\n    user {\n      id\n      weeklyEmail\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '04ad9a5f2643edeea0fbd906a26fb271';
export default node;
