
import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { UpdateDeviceConfigMutation } from '../__generated__/UpdateDeviceConfigMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation UpdateDeviceConfigMutation(
    $input: UpdateDeviceConfigInput!
  ) {
    updateDeviceConfig(input: $input) {
      device {
        id
        config {
          activityTimeout
          hardReset
          language
          languagesToShow
          startScreenText
          demo
          endMessageTime
          alertOnPageClose
          reverseSmileys
        }
      }
    }
  }
`;


export const useUpdateDeviceConfigMutation = ():[(config: UseMutationConfig<UpdateDeviceConfigMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<UpdateDeviceConfigMutation>(mutation);
  return [commit, isInFlight];
};





// import {commitMutation, graphql} from 'react-relay';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation UpdateDeviceConfigMutation(
//     $input: UpdateDeviceConfigInput!
//   ) {
//     updateDeviceConfig(input: $input) {
//       device {
//         id
//         config {
//           activityTimeout
//           hardReset
//           language
//           languagesToShow
//           startScreenText
//           demo
//           endMessageTime
//           alertOnPageClose
//           reverseSmileys
//         }
//       }
//     }
//   }
// `;

// function commit({deviceId, config}) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           deviceId,
//           config,
//         },
//       },
//     },
//   );
// }

// export default {commit};
