import { useState } from 'react';
import { grey200, grey400, red500 } from '../../../../theme';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import WarningIcon from '@mui/icons-material/Warning';

import {
  useUpdateDeviceConfigMutation,
} from '../../../../mutations';
import 'codemirror/lib/codemirror.css';
import CodeMirror from 'react-codemirror';
import 'codemirror/mode/javascript/javascript';



const styles = {
  configLabel: {
    color: grey400,
    display: 'block',
    fontSize: '16px',
    lineHeight: '22px',
    margin: '40px 0 10px',
  },
  configWrapper: {
    border: '1px solid' + grey200,
    minWidth: '50%',
    borderRadius: '2px',
  },
  invalidJSONLabel: {
    color: red500,
    float: 'right',
    display: 'inline-block',
    marginTop: '-24px',
  },
};



export function UpdateDeviceConfigDialog(props: any) {

  const { open, onClose } = props;

  const propsDevice = props.device || {};

  const [configString, setConfigString] = useState(JSON.stringify(props.device.config, null, 2));
  const [configObject, setConfigObject] = useState(propsDevice.config);
  const [configValidJSON, setConfigValidJSON] = useState(true);

  const [commitUpdateDeviceConfigMutation] = useUpdateDeviceConfigMutation();

  /**
   * 
   * @param newValue 
   * @param change 
   * @returns 
   */
  // function updateConfig(editor: any, data: any, value: string) {
  function updateConfig(newValue: string, change: CodeMirror.EditorChange) {


    if (!newValue) {
      return;
    }
    setConfigString(newValue);

    let configObject = {};

    try {
      configObject = JSON.parse(newValue);
    } catch (err) {
      setConfigValidJSON(false);
      return;
    }

    setConfigValidJSON(true);
    setConfigObject(configObject);

  }

  function save() {
    // UpdateDeviceConfigMutation.commit({
    //   deviceId: device.id,
    //   config: configObject,
    // });

    commitUpdateDeviceConfigMutation({
      variables: {
        input: {
          deviceId: propsDevice.id,
          config: configObject,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
      },
    });

    onClose();
  }

  function cancel() {

    setConfigString(JSON.stringify(propsDevice.config, null, 2));
    setConfigObject(propsDevice.config);
    setConfigValidJSON(true);

    onClose();
  }



  return (
    <Dialog
      sx={{

      }}
      open={!!open}
      title="Update device config"
      onClose={onClose}
    >
      <DialogContent
        sx={{
          minWidth: '500px',
        }}
      >
        <label style={styles.configLabel}>
          Config JSON
          {configValidJSON ?
            null
            :
            <Badge
              badgeContent={<WarningIcon sx={{ color: red500 }} />}
              sx={styles.invalidJSONLabel}
            >
              Invalid JSON
            </Badge>
          }
        </label>

        <div style={styles.configWrapper}>
          <CodeMirror
            value={configString}
            onChange={updateConfig}
            options={{
              mode: {
                name: 'javascript',
                json: true,
              },
              lineNumbers: true,
            }}
          />
        </div>

      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"

          onClick={cancel}
        >Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={save}
          disabled={!configValidJSON}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

}
