
export const notificationService = (function(): any {

  const data: {[key: string]: {[key: string]: number}} = {newQuestions: {}, newSurveys: {}};

  const QUESTIONS_KEY = 'newQuestions';

  const SURVEYS_KEY = 'newSurveys';

  /**
   * Read ids from local storage
   */
  function readFromStorage(key: string): {[key: string]: number} {
    try {
      const newQuestionsValue = localStorage.getItem(key);
      if (!newQuestionsValue) {
        return data[key];
      }
      const q = JSON.parse(newQuestionsValue);
      if (q && q.constructor === Object) {
        Object.assign(data[key], q);
      }
      // Remove unclosed notifications for questions older than 3 days
      Object.keys(data[key]).forEach((id: string) => {
        if (data[key][id] && data[key][id] + 60*60*24*3*1000 < Date.now()) {
          delete data[key][id];
        }
      });
      localStorage.setItem(key, JSON.stringify(data[key]));
    } catch {
      // In case there's anything wrong with reading data from local storage,
      // we consider new question list to be empty
      // and reset corrupted data
      localStorage.removeItem(key);
    }
    return data[key];
  }

  /**
   * Read new question ids stored in local storage
   */
  function readQuestions(): {[key: string]: number} {
    return readFromStorage(QUESTIONS_KEY);
  }

  /**
   * Read new survey ids stored in local storage
   */
  function readSurveys(): {[key: string]: number} {
    return readFromStorage(SURVEYS_KEY);
  }

  /**
   * Check if a question is new
   */
  function isNewQuestion(id: string): boolean {
    return id ? !!data[QUESTIONS_KEY][id] : false;
  }

  /**
   * Check if a survey is new
   */
  function isNewSurvey(id: string): boolean {
    return id ? !!data[SURVEYS_KEY][id] : false;
  }

  /**
   * Remove question from new questions list
   */
  function removeQuestion(id: string): void {
    if(!id) {  
      return;
    }
    delete data[QUESTIONS_KEY][id];
    localStorage.setItem(QUESTIONS_KEY, JSON.stringify(data[QUESTIONS_KEY]));
  }

  /**
   * Add a question to the question list
   */
  function addQuestion(id: string): void {
    if (!id) {
      return;
    }
    data[QUESTIONS_KEY][id] = Date.now();
    localStorage.setItem(QUESTIONS_KEY, JSON.stringify(data[QUESTIONS_KEY]));
  }

  /**
   * Remove survey from new surveys list
   */
  function removeSurvey(id: string): void {
    if(!id) {  
      return;
    }
    delete data[SURVEYS_KEY][id];
    localStorage.setItem(SURVEYS_KEY, JSON.stringify(data[SURVEYS_KEY]));
  }

  /**
   * Add a survey to the surveys list
   */
  function addSurvey(id: string): void {
    if (!id) {
      return;
    }
    data[SURVEYS_KEY][id] = Date.now();
    localStorage.setItem(SURVEYS_KEY, JSON.stringify(data[SURVEYS_KEY]));
  }

  readQuestions();
  readSurveys();

  return {
    removeQuestion,
    addQuestion,
    isNewQuestion,

    isNewSurvey,
    removeSurvey,
    addSurvey,
  };

})();
