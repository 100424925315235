import {
  useEffect,
  useState,
} from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  useUpdateAccountActiveMutation,
  useImpersonateAccountMutation,
} from '../../../../mutations';
import {
  DeviceList,
  UserList,
} from '../';
import {
  API_URL,
  DEFAULT_LOCALE,
} from '../../../../config';
import store from 'store';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {
  useQueryLoader,
} from "react-relay/hooks";
import { DevicesByAccountQuery } from '../../../../queries';
import { UsersByAccountQuery } from '../../../../queries';



export function AccountListItem(props: any) {

  const {
    account,
    accountList
  } = props;

  const accountId = account.id;

  const [
    usersQueryRef,
    usersLoadQuery,
  ] = useQueryLoader(UsersByAccountQuery);

  const [
    devicesQueryRef,
    devicesLoadQuery,
  ] = useQueryLoader(DevicesByAccountQuery);

  const [manageSurveysLoading, setManageSurveysLoading] = useState<boolean>(false);
  const [manageReportsLoading, setManageReportsLoading] = useState<boolean>(false);
  // const [users, setUsers] = useState<any>([]);
  // const [devices, setDevices] = useState<any>([]);

  const [manageSurveysThroughBuilderLoading, setManageSurveysThroughBuilderLoading] = useState<boolean>(false);

  const [commitUpdateAccountActiveMutation] = useUpdateAccountActiveMutation();
  const [commitImpersonateAccountMutation] = useImpersonateAccountMutation();

  function handleActiveToggle(event: React.ChangeEvent<HTMLInputElement>) {

    commitUpdateAccountActiveMutation({
      variables: {
        input: {
          accountId: accountId,
          active: !!event.target.checked,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
      },
    });

  }

  function manageSurveysThroughBuilder() {
    setManageSurveysThroughBuilderLoading(true);

    commitImpersonateAccountMutation({
      variables: {
        input: {
          accountId: accountId,
          callbackUrl: String(process.env.REACT_APP_BUILDER_URL || ''),
        },
      },
      updater: (store, payload) => {
        // 
        const url = (payload as any || {}).impersonateAccount.url;
        window.open(url);
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
        setManageSurveysThroughBuilderLoading(false);
      },
      onError: (error) => {
        setManageSurveysThroughBuilderLoading(false);
        // handleError(error);
        alert(error.message);
      },
    });
  }

  function manageSurveys() {
    setManageSurveysLoading(true);

    commitImpersonateAccountMutation({
      variables: {
        input: {
          accountId: accountId,
          callbackUrl: String(process.env.REACT_APP_BUILDER_URL || ''),
        },
      },
      updater: (store, payload) => {
        // 
        const url = (payload as any || {}).impersonateAccount.url;
        window.open(url);
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
        setManageSurveysLoading(false);
      },
      onError: (error) => {
        setManageSurveysLoading(false);
        // handleError(error);
        alert(error.message);
      },
    });

  }

  function manageReports() {
    setManageReportsLoading(true);

    commitImpersonateAccountMutation({
      variables: {
        input: {
          accountId: accountId,
          callbackUrl: 'https://reports.touch-and-tell.se/callback',
        },
      },
      updater: (store, payload) => {
        // 
        const url = (payload as any || {}).impersonateAccount?.url;
        if (url) {
          window.open(url);
        }
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
        setManageReportsLoading(false);
      },
      onError: (error) => {
        setManageReportsLoading(false);
        // handleError(error);
        alert(error.message);
      },
    });
  }

  function token() {
    const storedAuth = store.get('auth') || {};
    return storedAuth.token;
  }

  /**
   * Use effect
   */
  useEffect(() => {

    usersLoadQuery({
      accountId: accountId,
    }, { fetchPolicy: 'store-or-network' });

    devicesLoadQuery({
      accountId: accountId,
    }, { fetchPolicy: 'store-or-network' });

    // eslint-disable-next-line
  }, [usersLoadQuery, devicesLoadQuery, accountId])



  return (

    <Grid
      container
      sx={{
        mb: 3,
        maxWidth: '100%',
      }}
    >
      <Grid
        sx={{
          maxWidth: '100%',
        }}
        item>
        <Grid
          sx={{
            borderRadius: '4px',
            overflow: 'hidden',
          }}
        >
          <Grid
            sx={{
              border: '1px solid #c4e2e7',
              borderRadius: '4px',
              padding: 2,
              backgroundColor: 'white',
            }}

          >

            <Grid
              container
              spacing={2}
              justifyContent="s"
              flexDirection="row">
              <Grid
                sx={{
                  width: '50%',
                }}
                item>
                <Grid container flexDirection="column">
                  <Grid
                    sx={{
                      marginBottom: 0,
                    }}
                    item>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                      }}
                      variant="h6">
                      {account ? account.name : ''}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">
                      {account.identifier}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container flexDirection="column">
                  <Grid item>
                    <Typography>
                      ID: {account.mongoId}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Language: {account.language || DEFAULT_LOCALE}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          <Grid>

            <Grid container flexDirection="column">
              <Grid item>



                <Grid
                  spacing={1}
                  sx={{
                    paddingTop: 4,
                    paddingBottom: 4,
                  }}
                  container>

                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={account.active}
                            onChange={handleActiveToggle}
                          />
                        }
                        labelPlacement="end"
                        label="Active" />
                    </FormGroup>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const url: string = API_URL +
                          `/formattedAnswers?filter:account=${account.mongoId}` +
                          `&sort:time=descending&limit=10&token=${token()}`;
                        window.open(url);
                      }}
                    >
                      Export answers &rarr;
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={manageSurveysThroughBuilderLoading}
                      color="primary"
                      onClick={manageSurveysThroughBuilder}
                    >
                      {manageSurveysThroughBuilderLoading ? 'Loading...' : 'New Builder'}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={manageSurveysLoading}
                      color="primary"
                      onClick={manageSurveys}
                    >
                      {manageSurveysLoading ? 'Loading...' : 'Manage surveys'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={manageReportsLoading}
                      color="primary"
                      onClick={manageReports}
                    >
                      {manageReportsLoading ? 'Loading...' : 'Manage reports'}
                    </Button>
                  </Grid>

                </Grid>

              </Grid>



              <Grid
                sx={{
                  overflowX: 'scroll',
                  width: '100%',
                }}
                item>
                {devicesQueryRef ?
                  (<DeviceList
                    query={DevicesByAccountQuery}
                    queryRef={devicesQueryRef}
                    account={account}
                    accountList={accountList} />) : null}
              </Grid>

              <Grid
                sx={{
                  overflowX: 'scroll',
                  width: '100%',
                }}
                item>
                {usersQueryRef ? (<UserList
                  query={UsersByAccountQuery}
                  queryRef={usersQueryRef}
                  account={account} />) : null}
              </Grid>



            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </Grid>

  );
}


