import { useEffect } from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";



export function RootLayout() {

  const rootMatch = useMatch('/');

  const navigate = useNavigate();

  useEffect(() => {
    if (rootMatch) {
      navigate('/accounts')
    }
  }, [navigate, rootMatch]);

  return (
    <div id="root-layout">
      <Outlet />
    </div>
  )
}
