
import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { UpdateUserWeeklyEmailMutation } from '../__generated__/UpdateUserWeeklyEmailMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation UpdateUserWeeklyEmailMutation(
    $input: UpdateUserWeeklyEmailInput!
  ) {
    updateUserWeeklyEmail(input: $input) {
      user {
        id
        weeklyEmail
      }
    }
  }
`;



export const useUpdateUserWeeklyEmailMutation = (): [(config: UseMutationConfig<UpdateUserWeeklyEmailMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<UpdateUserWeeklyEmailMutation>(mutation);
  return [commit, isInFlight];
};


