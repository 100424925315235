import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";



export const CallbackPage = () => {

  const {
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const navigate = useNavigate();

  // console.log('CallbackPage');
  // console.log('_______________');
  // console.log('user', user);
  // console.log('error', error);
  // console.log('token', 1);
  // console.log('isAuthenticated', isAuthenticated);

  useEffect(() => {
    (async () => {

      if (!isLoading && isAuthenticated && !error) {
        navigate('/accounts');
      } else {
        loginWithRedirect();
      }

    })()
  }, [loginWithRedirect, navigate, isLoading, isAuthenticated, error]);

  return error ?
    (
      <div>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Error
          </h1>
          <div className="content__body">
            <p id="page-description">
              <span>{
                error.message
              }</span>
            </p>

          </div>
        </div>
      </div>
    )
    : (
      <div className="page-layout">
        <div className="page-layout__content">
          Callback
        </div>
      </div>
    );

};


