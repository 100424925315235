
import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { UpdateUserPermissionsMutation } from '../__generated__/UpdateUserPermissionsMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation UpdateUserPermissionsMutation(
    $input: UpdateUserPermissionsInput!
  ) {
    updateUserPermissions(input: $input) {
      user {
        id
        accessToDevices {
          id
          name
        }
        accessToReports {
          id
          name
        }
      }
    }
  }
`;


export const useUpdateUserPermissionsMutation = ():[(config: UseMutationConfig<UpdateUserPermissionsMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<UpdateUserPermissionsMutation>(mutation);
  return [commit, isInFlight];
};



// import {commitMutation, graphql} from 'react-relay';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation UpdateUserPermissionsMutation(
//     $input: UpdateUserPermissionsInput!
//   ) {
//     updateUserPermissions(input: $input) {
//       user {
//         id
//         accessToDevices {
//           id
//           name
//         }
//         accessToReports {
//           id
//           name
//         }
//       }
//     }
//   }
// `;

// function commit({userId, devices, reports}) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           userId,
//           devices,
//           reports,
//         },
//       },
//     },
//   );
// }

// export default {commit};
