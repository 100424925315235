/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AdminDevicesOrderByInputObjectType = {
    createdAt?: number | null | undefined;
    name?: number | null | undefined;
    status?: number | null | undefined;
    lastFetched?: number | null | undefined;
    expectActive?: number | null | undefined;
    isNewCollect?: number | null | undefined;
};
export type AdminDevicesFilterInputObjectType = {
    mongoId?: string | null | undefined;
    udid?: string | null | undefined;
    supportId?: string | null | undefined;
    name?: string | null | undefined;
    deviceType?: string | null | undefined;
    status?: string | null | undefined;
    isNewCollect?: boolean | null | undefined;
};
export type DevicesQueryVariables = {
    orderBy: AdminDevicesOrderByInputObjectType;
    filter: AdminDevicesFilterInputObjectType;
};
export type DevicesQueryResponse = {
    readonly adminDevices: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly mongoId: string | null;
                readonly udid: string | null;
                readonly collectVersion: string | null;
                readonly supportId: string | null;
                readonly deviceType: string | null;
                readonly name: string | null;
                readonly lastFetched: string | null;
                readonly status: string | null;
                readonly createdAt: string | null;
                readonly expectActive: boolean | null;
                readonly account: string | null;
                readonly isNewCollect: boolean | null;
                readonly accountData: {
                    readonly id: string;
                    readonly mongoId: string | null;
                    readonly name: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};
export type DevicesQuery = {
    readonly response: DevicesQueryResponse;
    readonly variables: DevicesQueryVariables;
};



/*
query DevicesQuery(
  $orderBy: AdminDevicesOrderByInputObjectType!
  $filter: AdminDevicesFilterInputObjectType!
) {
  adminDevices(orderBy: $orderBy, filter: $filter) {
    edges {
      node {
        id
        mongoId
        udid
        collectVersion
        supportId
        deviceType
        name
        lastFetched
        status
        createdAt
        expectActive
        account
        isNewCollect
        accountData {
          id
          mongoId
          name
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mongoId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      }
    ],
    "concreteType": "DeviceConnection",
    "kind": "LinkedField",
    "name": "adminDevices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "udid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collectVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "supportId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deviceType",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastFetched",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expectActive",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "account",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isNewCollect",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountData",
                "kind": "LinkedField",
                "name": "accountData",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DevicesQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DevicesQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "2e9fc8b0d8587eca8f8af4cbd191af34",
    "id": null,
    "metadata": {},
    "name": "DevicesQuery",
    "operationKind": "query",
    "text": "query DevicesQuery(\n  $orderBy: AdminDevicesOrderByInputObjectType!\n  $filter: AdminDevicesFilterInputObjectType!\n) {\n  adminDevices(orderBy: $orderBy, filter: $filter) {\n    edges {\n      node {\n        id\n        mongoId\n        udid\n        collectVersion\n        supportId\n        deviceType\n        name\n        lastFetched\n        status\n        createdAt\n        expectActive\n        account\n        isNewCollect\n        accountData {\n          id\n          mongoId\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '742e9f36c05d6d666475e7b8b78050e1';
export default node;
