// https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=00abc5&secondary.color=ff990c&primary.text.color=ffffff&secondary.text.color=ffffff
import { forwardRef } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import {
  primaryColor,
  primaryLightColor,
  primaryDarkColor,
  primaryTextColor,
  secondaryColor,
  secondaryLightColor,
  secondaryDarkColor,
  secondaryTextColor,
} from './tntColors';
import {
  fontFamilyPrimary,
} from './tntFonts';

const LinkBehavior = forwardRef<
  any,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const tntTheme: any = {
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  typography: {
    h1: {
      fontSize: '3.5rem',
    },
    h2: {
      fontSize: '3rem',
    },
    h3: {
      fontSize: '2.5rem',
    },
    fontFamily: fontFamilyPrimary,
  },
  palette: {
    primary: {
      light: primaryLightColor,
      main: primaryColor,
      dark: primaryDarkColor,
      contrastText: primaryTextColor,
    },
    secondary: {
      light: secondaryLightColor,
      main: secondaryColor,
      dark: secondaryDarkColor,
      contrastText: secondaryTextColor,
    },
  },

};

export default tntTheme;


