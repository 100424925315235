
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {
  useDeleteDeviceMutation,
} from '../../../../mutations';



export function DeleteDeviceConfirmationDialog(props: any) {

  const { onClose, open, device } = props;

  const [commitDeleteDeviceMutation] = useDeleteDeviceMutation();

  function remove() {

    commitDeleteDeviceMutation({
      variables: {
        input: {
          id: device.id,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
        window.location.href = window.location.href.toString();
      },
      onError: (error) => {
        // handleError(error);
      },
    });

    // DeleteDeviceMutation.commit({
    //   id: device.id,
    // });
    onClose();
  }

  function cancel() {
    onClose();
  }

  return (
    <Dialog
      title="Are you sure you want to delete this device?"
      open={open}
      onClose={onClose}
    >

      <DialogActions>
        <Button
          variant="contained"
          disableElevation={true}
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={remove}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

}

// export default createFragmentContainer(
//   DeleteDeviceConfirmationDialog,
//   { deletedeviceconfirmationdialog: graphql`
//     fragment DeleteDeviceConfirmationDialog_device on Device {
//       id
//     }
//   `},
// );
