
import { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { grey400 } from '../../../../theme';
import {
  useUpdateUserPermissionsMutation,
} from '../../../../mutations';
import _ from 'lodash';


/**
 * getStateFromProps
 * @param props 
 * @returns 
 */
function getStateFromProps(props: any) {
  return {
    sd: _.map(props.user.accessToDevices, 'id'),
    sr: _.map(props.user.accessToReports, 'id'),
  };
}

const styles = {
  formLabel: {
    color: grey400,
    display: 'block',
    fontSize: '16px',
    lineHeight: '22px',
    margin: '40px 0 10px',
  },
  listAll: {
    marginBottom: 10,
  },
  listDivider: {
    marginBottom: 10,
    width: 300,
  },
  listItem: {
    marginTop: 5,
    marginBottom: 5,
  },
};

export function EditUserPermissionsDialog(props: any) {

  const {
    open,
    onClose,
  } = props;

  const propsUser = props.user || {};
  const propsAccount = props.account || {};

  const {
    sd,
    sr,
  } = getStateFromProps(props);

  const [selectedDevices, setSelectedDevices] = useState<any[]>(sd);
  const [selectedReports, setSelectedReports] = useState<any[]>(sr);

  const [commitUpdateUserPermissionsMutation] = useUpdateUserPermissionsMutation();

  function updateSelectedDevices(deviceId: any, checked: any) {
    const newSelectedDevices = checked
      ? _.union(selectedDevices, [deviceId])
      : _.without(selectedDevices, deviceId);

    setSelectedDevices(newSelectedDevices);
  }

  function selectAllDevices() {
    setSelectedDevices([]);
  }

  function updateSelectedReports(reportId: any, checked: any) {
    const newSelectedReports = checked
      ? _.union(selectedReports, [reportId])
      : _.without(selectedReports, reportId);

    setSelectedReports(newSelectedReports);
  }

  function selectAllReports() {
    setSelectedReports([]);
  }

  function save() {

    commitUpdateUserPermissionsMutation({
      variables: {
        input: {
          userId: propsUser.id,
          devices: selectedDevices,
          reports: selectedReports,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
      },
    });

    // UpdateUserPermissionsMutation.commit({
    //   userId: user.id,
    //   devices: selectedDevices,
    //   reports: selectedReports,
    // });
    onClose();
  }

  function cancel() {

    const {
      sd,
      sr,
    } = getStateFromProps(props);

    setSelectedDevices(sd);
    setSelectedReports(sr);

    onClose();
  }



  const accountDeviceEdges =
    (propsAccount && propsAccount.devices && propsAccount.devices.edges) || [];
  const accountDevices = accountDeviceEdges.map((edge: any) => edge ? edge.node : null).filter((d: any) => d && d.name !== 'Demo');

  const accountReportEdges =
    (propsAccount && propsAccount.reports && propsAccount.reports.edges) || [];
  const accountReports = accountReportEdges.map((edge: any) => edge ? edge.node : null).filter(Boolean);



  return (
    <Dialog
      title="Edit permissions"
      open={open}

      onClose={cancel}
    >

      <DialogContent>
        <label style={styles.formLabel}>
          Devices
        </label>

        <FormGroup>
          <FormControlLabel control={
            <Checkbox
              checked={selectedDevices && selectedDevices.length <= 0}
              onChange={selectAllDevices}
              style={styles.listAll}
            />
          } label="All" />
        </FormGroup>

        <Divider style={styles.listDivider} />
        {accountDevices.map((device: any) =>
          <FormGroup
            key={`accountDevices${device.id}`}
          >
            <FormControlLabel control={
              <Checkbox
                key={device.id}
                checked={_.includes(selectedDevices, device.id)}
                onChange={(event, checked) => updateSelectedDevices(device.id, checked)}
                style={styles.listItem}
              />
            } label={device ? device.name : ''} />
          </FormGroup>
        )}

        <label style={styles.formLabel}>
          Reports
        </label>

        <FormGroup>
          <FormControlLabel control={
            <Checkbox
              checked={selectedReports && selectedReports.length <= 0}
              onChange={selectAllReports}
              style={styles.listAll}
            />
          } label="All" />
        </FormGroup>

        <Divider style={styles.listDivider} />
        {accountReports.map((report: any) =>
          <FormGroup
            key={`accountDevices${report.id}`}
          >
            <FormControlLabel control={
              <Checkbox
                key={report.id}
                checked={_.includes(selectedReports, report.id)}
                onChange={(event, checked) => updateSelectedReports(report.id, checked)}
                style={styles.listItem}
              />
            } label={report ? report.name : ''} />
          </FormGroup>
        )}
      </DialogContent>


      <DialogActions>
        <Button
          variant="contained"
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={save}
        >
          Save
        </Button>
      </DialogActions>

    </Dialog>
  );

}

// export default createFragmentContainer(
//   EditUserPermissionsDialog,
//   {
//   edituserpermissionsdialogaccount: graphql`
//     fragment EditUserPermissionsDialog_account on Account {
//       devices(
//         first: 2147483647 # max GraphQLInt
//       ) @connection(key: "EditUserPermissionsDialog_devices") {
//         edges {
//           node {
//             id
//             name
//           }
//         }
//       }
//       reports(
//         first: 2147483647 # max GraphQLInt
//       ) @connection(key: "EditUserPermissionsDialog_reports") {
//         edges {
//           node {
//             id
//             name
//           }
//         }
//       }
//     }`,
//   edituserpermissionsdialoguser: graphql`
//     fragment EditUserPermissionsDialog_user on User {
//       id
//       accessToDevices {
//         id
//       }
//       accessToReports {
//         id
//       }
//     }`,
//   },
// );
