/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteDeviceInput = {
    id: string;
    clientMutationId?: string | null | undefined;
};
export type DeleteDeviceMutationVariables = {
    input: DeleteDeviceInput;
};
export type DeleteDeviceMutationResponse = {
    readonly deleteDevice: {
        readonly id: string | null;
        readonly accountId: string | null;
    } | null;
};
export type DeleteDeviceMutation = {
    readonly response: DeleteDeviceMutationResponse;
    readonly variables: DeleteDeviceMutationVariables;
};



/*
mutation DeleteDeviceMutation(
  $input: DeleteDeviceInput!
) {
  deleteDevice(input: $input) {
    id
    accountId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteDevicePayload",
    "kind": "LinkedField",
    "name": "deleteDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteDeviceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteDeviceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5392bfd6f0e09fcdfc37913d9f8587a8",
    "id": null,
    "metadata": {},
    "name": "DeleteDeviceMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteDeviceMutation(\n  $input: DeleteDeviceInput!\n) {\n  deleteDevice(input: $input) {\n    id\n    accountId\n  }\n}\n"
  }
};
})();
(node as any).hash = '7e82863795bbd710eb570d3e6d4b8409';
export default node;
