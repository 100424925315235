/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UsersByAccountQueryVariables = {
    accountId?: string | null | undefined;
};
export type UsersByAccountQueryResponse = {
    readonly usersByAccount: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string | null;
                readonly firstName: string | null;
                readonly lastName: string | null;
                readonly email: string | null;
                readonly language: string | null;
                readonly weeklyEmail: boolean | null;
                readonly monthlyEmail: boolean | null;
                readonly advancedMode: boolean | null;
                readonly accessToDevices: ReadonlyArray<{
                    readonly id: string;
                } | null> | null;
                readonly accessToReports: ReadonlyArray<{
                    readonly id: string;
                } | null> | null;
            } | null;
        } | null> | null;
    } | null;
};
export type UsersByAccountQuery = {
    readonly response: UsersByAccountQueryResponse;
    readonly variables: UsersByAccountQueryVariables;
};



/*
query UsersByAccountQuery(
  $accountId: ID
) {
  usersByAccount(accountId: $accountId) {
    edges {
      node {
        id
        name
        firstName
        lastName
        email
        language
        weeklyEmail
        monthlyEmail
        advancedMode
        accessToDevices {
          id
        }
        accessToReports {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      }
    ],
    "concreteType": "UserConnection",
    "kind": "LinkedField",
    "name": "usersByAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "language",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weeklyEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "monthlyEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "advancedMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "accessToDevices",
                "plural": true,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Report",
                "kind": "LinkedField",
                "name": "accessToReports",
                "plural": true,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersByAccountQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UsersByAccountQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "868af67e88b035d101af13b6573df640",
    "id": null,
    "metadata": {},
    "name": "UsersByAccountQuery",
    "operationKind": "query",
    "text": "query UsersByAccountQuery(\n  $accountId: ID\n) {\n  usersByAccount(accountId: $accountId) {\n    edges {\n      node {\n        id\n        name\n        firstName\n        lastName\n        email\n        language\n        weeklyEmail\n        monthlyEmail\n        advancedMode\n        accessToDevices {\n          id\n        }\n        accessToReports {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4fbdc469f1814fd347fee95d854e27d7';
export default node;
