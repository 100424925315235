
import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { UpdateDeviceTagsMutation } from '../__generated__/UpdateDeviceTagsMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation UpdateDeviceTagsMutation(
    $input: UpdateDeviceTagsInput!
  ) {
    updateDeviceTags(input: $input) {
      device {
        id
        tags
      }
    }
  }
`;


export const useUpdateDeviceTagsMutation = ():[(config: UseMutationConfig<UpdateDeviceTagsMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<UpdateDeviceTagsMutation>(mutation);
  return [commit, isInFlight];
};






// import {commitMutation, graphql} from 'react-relay';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation UpdateDeviceTagsMutation(
//     $input: UpdateDeviceTagsInput!
//   ) {
//     updateDeviceTags(input: $input) {
//       device {
//         id
//         tags
//       }
//     }
//   }
// `;

// function commit({deviceId, tags}) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           deviceId,
//           tags,
//         },
//       },
//     },
//   );
// }

// export default {commit};
