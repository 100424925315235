import { ChangeEvent, useState } from 'react';
import moment from 'moment-timezone';
import {
  tntFadedRed,
  tntFadedGreen,
} from '../../../../theme';
import OnlineIcon from '@mui/icons-material/WifiOff';
import OfflineIcon from '@mui/icons-material/Wifi';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Switch from '@mui/material/Switch';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  UpdateDeviceCollectVersionDialog,
  UpdateDeviceConfigDialog,
  DeleteDeviceConfirmationDialog,
  TransferDeviceDialog,
} from '../';
import {
  useUpdateDeviceExpectActiveMutation,
  useUpdateDeviceTimezoneMutation,
  useUpdateDeviceTagsMutation,
} from '../../../../mutations';
import { Button } from '@mui/material';
import { CreateReplacementDeviceDialog } from '../CreateReplacementDeviceDialog/CreateReplacementDeviceDialog';



const styles = {
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  verticalAlignColumn: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',

  },
  configIconButton: {
    marginLeft: -8,
  },
  expectActiveToggle: {
    marginLeft: -6,
  },
  deleteIconButton: {
    marginLeft: -8,
  },
  timezoneSelect: {
    width: 140,
  },
  timezoneSelectLabel: {
    fontSize: 13,
    paddingRight: 210,
  },
  timezoneSelectIcon: {
    width: 24,
    padding: 0,
    right: 180,
  },
  timezoneSelectMenu: {
    width: 320,
  },
};



export function DeviceListItem(props: any) {

  const { accountList, device, tags, relay, ...tableProps } = props;

  const [updateCollectVersionDialogOpen, setUpdateCollectVersionDialogOpen] = useState<boolean>(false);
  const [updateConfigDialogOpen, setUpdateConfigDialogOpen] = useState<boolean>(false);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState<boolean>(false);
  const [createReplacementDeviceDialogOpen, setCreateReplacementDeviceDialogOpen] = useState<boolean>(false);
  const [transferDeviceDialogOpen, setTransferDeviceDialogOpen] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<string>('');
  const [commitUpdateDeviceExpectActiveMutation] = useUpdateDeviceExpectActiveMutation();
  const [commitUpdateDeviceTimezoneMutation] = useUpdateDeviceTimezoneMutation();
  const [commitUpdateDeviceTagsMutation] = useUpdateDeviceTagsMutation();

  /**
   * Show transfer dialog
   */
  function showTransferDeviceDialog() {
    setTransferDeviceDialogOpen(true);
  }

  /**
   * Hide transfer dialog
   */
  function hideTransferDeviceDialog() {
    setTransferDeviceDialogOpen(false);
  }

  /**
   * Show create replacement dialog
   */
  function showCreateReplacementDeviceDialog() {
    setCreateReplacementDeviceDialogOpen(true);
  }

  /**
   * Hide create replacement dialog
   */
  function hideCreateReplacementDeviceDialog() {
    setCreateReplacementDeviceDialogOpen(false);
  }

  function showUpdateCollectVersionDialog() {
    setUpdateCollectVersionDialogOpen(true);
  }

  function hideUpdateCollectVersionDialog() {
    setUpdateCollectVersionDialogOpen(false);
  }

  function showUpdateConfigDialog() {
    setUpdateConfigDialogOpen(true);
  }

  function hideUpdateConfigDialog() {
    setUpdateConfigDialogOpen(false);
  }

  function showDeleteConfirmationDialog() {
    setDeleteConfirmationDialogOpen(true);
  }

  function hideDeleteConfirmationDialog() {
    setDeleteConfirmationDialogOpen(false);
  }

  function handleExpectActiveToggle(event: ChangeEvent<HTMLInputElement>) {

    commitUpdateDeviceExpectActiveMutation({
      variables: {
        input: {
          deviceId: device.id,
          expectActive: !!event.target.checked,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
      },
    });

    // UpdateDeviceExpectActiveMutation.commit({
    //   deviceId: device.id,
    //   expectActive: value,
    // });
  }

  function handleTimezoneChange(event: SelectChangeEvent) {
    const { value } = event.target;

    commitUpdateDeviceTimezoneMutation({
      variables: {
        input: {
          deviceId: device.id,
          timezone: value,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
      },
    });

    // UpdateDeviceTimezoneMutation.commit({
    //   deviceId: device.id,
    //   timezone: value,
    // });
  }

  function handleTagsChange(event: SelectChangeEvent) {

    const { value } = event.target;

    // console.log(value)

    if (!value || (value as any).indexOf(null) !== -1) {
      return null;
    }

    commitUpdateDeviceTagsMutation({
      variables: {
        input: {
          deviceId: device.id,
          tags: (value as any),
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
      },
    });

    // UpdateDeviceTagsMutation.commit({
    //   deviceId: device.id,
    //   tags: value,
    // });
  }

  function handleTextFieldChange(event: ChangeEvent<HTMLInputElement>) {
    setNewTag(event.target.value.trim());
  }

  function onKeyPress(event: any) {

    // console.log(newTag)

    const tags = (device.tags || []).filter(Boolean).map((t: any) => t.trim()).filter((t: any) => !!t);
    if (event.key === 'Enter' && newTag) {


      commitUpdateDeviceTagsMutation({
        variables: {
          input: {
            deviceId: device.id,
            tags: [...tags, newTag.trim()],
          },
        },
        updater: (store, payload) => {
          // 
        },
        onCompleted: () => {
          // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
        },
        onError: (error) => {
          // handleError(error);
        },
      });

      // UpdateDeviceTagsMutation.commit({
      //   deviceId: device.id,
      //   tags: [...tags, newTag.trim()],
      // });
      setNewTag('');
    }
  }



  const timezoneMenuItems = moment.tz.names().map((tz: any) => <MenuItem value={tz} key={tz}> {tz} </MenuItem>);

  const tagsMenuItems = (tags || []).map((tag: any) => <MenuItem value={tag} key={tag}> {tag} </MenuItem>);

  return (
    <TableRow {...tableProps}>
      <TableCell>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://collect.touchandtell.com/device/${device.mongoId}&demo=true`}
          style={styles.link}
        >
          {device ? device.name : ''}
        </a>
      </TableCell>
      <TableCell>
        {device.location}
      </TableCell>
      <TableCell>
        {device.deviceType}
      </TableCell>
      <TableCell>
        {device.mongoId}
      </TableCell>
      <TableCell>
        {
          device.isOnline ?
            <OnlineIcon sx={{
              color: tntFadedGreen,
              width: 20,
              height: 20,
            }} />
            :
            <OfflineIcon sx={{
              color: tntFadedRed,
              width: 20,
              height: 20,
            }} />
        }
      </TableCell>
      <TableCell>
        {device.lastFetched ? moment(device.lastFetched).fromNow() : 'Never'}
      </TableCell>
      <TableCell>
        <Switch
          checked={device.expectActive}
          onChange={handleExpectActiveToggle}
        // sx={styles.expectActiveToggle}
        />
      </TableCell>
      <TableCell>
        <div style={styles.verticalAlignColumn}>
          <span>{device.collectVersion || 'Latest'}</span>

          <IconButton
            onClick={showUpdateCollectVersionDialog}
            aria-label="Edit">
            <EditIcon />
          </IconButton>

          <UpdateDeviceCollectVersionDialog
            device={device}
            open={updateCollectVersionDialogOpen}
            onClose={hideUpdateCollectVersionDialog}
          />
        </div>
      </TableCell>

      <TableCell>
        <div style={styles.verticalAlignColumn}>

          {/* // ToDo: 
          // update styles properly 
          // style labelStyle iconStyle menuStyle */}
          <Select
            value={device.timezone}
            onChange={handleTimezoneChange}
            sx={{
              // ...styles.timezoneSelect,
              // ...styles.timezoneSelectLabel,
              // ...styles.timezoneSelectIcon,
              // ...styles.timezoneSelectMenu,
            }}
          >
            {timezoneMenuItems}
          </Select>
        </div>
      </TableCell>

      <TableCell>
        <div style={styles.verticalAlignColumn}>
          <Select
            multiple={true}
            value={device.tags}
            onChange={handleTagsChange}

            // ToDo: 
            // update styles properly 
            // style labelStyle iconStyle menuStyle
            sx={{
              // ...styles.timezoneSelect,
              // ...styles.timezoneSelectLabel,
              // ...styles.timezoneSelectIcon,
              // ...styles.timezoneSelectMenu,
            }}
          >
            <MenuItem key="item">
              <TextField
                // disabled={true}
                onKeyPress={onKeyPress}
                onChange={handleTextFieldChange}
                placeholder="Add a new tag and press Enter" />
            </MenuItem>
            {tagsMenuItems}
          </Select>
        </div>
      </TableCell>

      <TableCell>
        <div style={styles.verticalAlignColumn}>

          <div>
            <p>
              <Button
                variant="outlined"
                color="primary"
                title="Transfer device to another account"
                size="small"
                onClick={() => {
                  showTransferDeviceDialog();
                }}
              >
                Transfer
              </Button>

              <TransferDeviceDialog
                accountList={accountList}
                device={device}
                open={transferDeviceDialogOpen}
                onClose={hideTransferDeviceDialog} />
            </p>
            <p>
              <Button
                variant="outlined"
                color="primary"
                title="Create a new device that will replace the current device on enrollment"
                size="small"
                onClick={() => {
                  showCreateReplacementDeviceDialog();
                }}
              >
                Create replacement
              </Button>

              <CreateReplacementDeviceDialog
                device={device}
                open={createReplacementDeviceDialogOpen}
                onClose={hideCreateReplacementDeviceDialog} />
            </p>
          </div>

        </div>
      </TableCell>

      <TableCell>
        <div style={styles.verticalAlignColumn}>

          <IconButton
            style={styles.configIconButton}
            onClick={showUpdateConfigDialog}
            aria-label="Settings">
            <SettingsIcon />
          </IconButton>

          <UpdateDeviceConfigDialog
            device={device}
            open={updateConfigDialogOpen}
            onClose={hideUpdateConfigDialog}
          />
        </div>
      </TableCell>
      <TableCell>
        <div style={styles.verticalAlignColumn}>

          <IconButton
            style={styles.deleteIconButton}
            onClick={showDeleteConfirmationDialog}
            aria-label="Delete">
            <DeleteIcon />
          </IconButton>

          <DeleteDeviceConfirmationDialog
            device={device}
            open={deleteConfirmationDialogOpen}
            onClose={hideDeleteConfirmationDialog}
          />

        </div>
      </TableCell>
    </TableRow>
  );

}


