import {
  useEffect,
} from 'react';
import {
  useQueryLoader,
} from "react-relay/hooks";
import Grid from '@mui/material/Grid';
import { AccountsQuery } from '../../queries';
import AccountListData from './components/AccountListData/AccountListData';
import { AppLoader } from '../AppLoader/AppLoader';
import { DashboardLayout } from '../DashboardLayout/DashboardLayout';



export default function AccountList(props: any) {

  const query = AccountsQuery;

  const [
    queryRef,
    loadQuery,
  ] = useQueryLoader(query);


  /**
   * Use effect
   */
  useEffect(() => {
    loadQuery(
      {
        count: 20,
      },
      /// export type PreloadFetchPolicy = 'store-or-network' | 'store-and-network' | 'network-only';
      { fetchPolicy: 'store-or-network' },
    );
    // eslint-disable-next-line
  }, [loadQuery])

  return (
    <DashboardLayout>
      <Grid
        sx={{
          width: '100%',
          maxWidth: '100%',
          position: 'relative',
        }}
        container
        flexDirection="column">
        {!!queryRef ?
          <AccountListData query={query} queryRef={queryRef} />
          : <AppLoader />}
      </Grid>
    </DashboardLayout>
  );
}
