import { Header } from '../';
import { Footer } from '../';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const dashboardWrapperStyles: any = {
  width: '100%', 
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
};

const dashboardContainerStyles: any = {
  py: 2,
  flexGrow: 1,
};

export function DashboardLayout({children}: any) {
  return (
    <Box sx={dashboardWrapperStyles}>
      <Header />
      <Container sx={dashboardContainerStyles}>
        {children}
      </Container>  
      <Footer />
    </Box>
  );
}
