
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AppLoader } from "../views";



export const AuthenticationGuard = ({ component }: any) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <AppLoader />
      </div>
    ),
  });
  return <Component />;
};
