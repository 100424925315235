import {
  useState,
} from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import KeyIcon from '@mui/icons-material/Key';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  EditUserPermissionsDialog,
} from '../';
import {
  useUpdateUserWeeklyEmailMutation,
  useUpdateUserMonthlyEmailMutation,
  useCreateUserJWTMutation,
  useImpersonateUserMutation,
  useGenerateTokenMutation,
} from '../../../../mutations';



const styles = {
  verticalAlignColumn: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    alignItems: 'center',
  },
  weeklyEmailToggle: {
    marginLeft: -6,
  },
  monthlyEmailToggle: {
    marginLeft: -6,
  },
  settingsIconButton: {
    marginLeft: -6,
  },
  keyIconButton: {
    marginLeft: -6,
  },
  arrowForwardIconButton: {
    marginLeft: -6,
  },
  demoLinkToggle: {
    // width: 200,
    margin: '10px 0',
  },
  link: {
    cursor: 'pointer',
  },
};

export function UserListItem(props: any) {

  const { relay, ...tableProps } = props;

  const propsUser = props.user || {};
  const propsAccount = props.account || {};



  let displayName;
  if (propsUser && propsUser.name) {
    displayName = propsUser.name;
  } else if (propsUser.firstName || propsUser.lastName) {
    displayName = propsUser.firstName + ' ' + propsUser.lastName;
  } else {
    displayName = '';
  }


  const [editPermissionsDialogOpen, setEditPermissionsDialogOpen] = useState(false);
  const [loginLinkDialogOpen, setLoginLinkDialogOpen] = useState(false);
  const [loginJWT, setLoginJWT] = useState(null);
  const [isDemoLink, setIsDemoLink] = useState(true);
  const [impersonationLoading, setImpersonationLoading] = useState(false);


  const reportsAuthCallbackUrl = 'https://reports.touch-and-tell.se/callback';
  const loginLink = isDemoLink ?
    `${reportsAuthCallbackUrl}?demo=true&token=${loginJWT}` :
    `${reportsAuthCallbackUrl}?token=${loginJWT}`;

  const [commitUpdateUserWeeklyEmailMutation] = useUpdateUserWeeklyEmailMutation();
  const [commitUpdateUserMonthlyEmailMutation] = useUpdateUserMonthlyEmailMutation();
  const [commitCreateUserJWTMutation] = useCreateUserJWTMutation();
  const [commitImpersonateUserMutation] = useImpersonateUserMutation();
  const [commitGenerateTokenMutation] = useGenerateTokenMutation();

  function handleWeeklyEmailToggle(event: React.ChangeEvent<HTMLInputElement>) {

    commitUpdateUserWeeklyEmailMutation({
      variables: {
        input: {
          userId: propsUser.id,
          weeklyEmail: !!event.target.checked,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
      },
    });

    // UpdateUserWeeklyEmailMutation.commit({
    //   userId: user.id,
    //   weeklyEmail: event.target.value,
    // });
  }

  function handleMonthlyEmailToggle(event: React.ChangeEvent<HTMLInputElement>) {
    // UpdateUserMonthlyEmailMutation.commit({
    //   userId: user.id,
    //   monthlyEmail: event.target.value,
    // });


    commitUpdateUserMonthlyEmailMutation({
      variables: {
        input: {
          userId: propsUser.id,
          monthlyEmail: !!event.target.checked,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
      },
    });
  }

  function showEditPermissionsDialog() {
    setEditPermissionsDialogOpen(true);
  }

  function hideEditPermissionsDialog() {
    setEditPermissionsDialogOpen(false);
  }

  function createLoginLink() {
    // CreateUserJWTMutation.commit(
    //   {
    //     email: user.email,
    //   },
    //   (err: any, jwt: any) => {
    //     if (err) {
    //       alert(err);
    //     } else {

    //       setLoginJWT(jwt);
    //       setLoginLinkDialogOpen(true);

    //     }
    //   },
    // );

    commitCreateUserJWTMutation({
      variables: {
        input: {
          email: propsUser.email,
        },
      },
      updater: (store, payload) => {
        const jwt = (payload as any || {}).createUserJWT.jwt;

        setLoginJWT(jwt);
        setLoginLinkDialogOpen(true);
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
        alert(error.message);
      },
    });
  }

  function handleDemoLinkToggle(event: React.ChangeEvent<HTMLInputElement>) {
    setIsDemoLink(!!event.target.checked);
  }

  function handleLoginLinkDialogClose() {
    setLoginJWT(null);
    setIsDemoLink(true);
    setLoginLinkDialogOpen(false);
  }

  function impersonate() {
    setImpersonationLoading(true);


    commitImpersonateUserMutation({
      variables: {
        input: {
          userId: propsUser.id,
          callbackUrl: 'https://reports.touch-and-tell.se/callback',
        },
      },
      updater: (store, payload) => {
        const url = (payload as any || {}).impersonateUser.url;
        window.open(url);
      },
      onCompleted: () => {
        setImpersonationLoading(false);
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        setImpersonationLoading(false);
        // handleError(error);
        alert(error.message);
      },
    });

    // ImpersonateUserMutation.commit(
    //   {
    //     userId: user.id,
    //     callbackUrl: 'https://reports.touch-and-tell.se/callback',
    //   },
    //   (err: any, url: any) => {
    //     setImpersonationLoading(false);
    //     if (err) {
    //       alert(err);
    //     } else {
    //       window.open(url);
    //     }
    //   },
    // );
  }

  function impersonateNew(url: string) {
    setImpersonationLoading(true);

    commitGenerateTokenMutation({
      variables: {
        input: {
          id: propsUser.id,
          role: 'user',
        },
      },
      updater: (store, payload) => {
        const token = (payload as any || {}).generateToken.token;
        window.open(`${url}${token}`);
      },
      onCompleted: () => {
        setImpersonationLoading(false);
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        setImpersonationLoading(false);
        // handleError(error);
        alert(error.message);
      },
    });

    // GenerateTokenMutation.commit(
    //   {
    //     id: user.id,
    //     role: 'user',
    //   },
    //   (err: any, token: any) => {
    //     setImpersonationLoading(false);
    //     if (err) {
    //       alert(err);
    //     } else {
    //       window.open(`${url}${token}`);
    //     }
    //   },
    // );
  }

  function impersonateReportsNew() {
    const url = `${process.env.REACT_APP_REPORTS_URL_PROD}/callback?token=`;
    return impersonateNew(url);
  }

  function impersonateBuilderNew() {
    const url = `${process.env.REACT_APP_BUILDER_URL_PROD}/surveys?token=`;
    return impersonateNew(url);
  }

  return (
    <TableRow {...tableProps}>
      <TableCell>{displayName}</TableCell>
      <TableCell>{propsUser.email}</TableCell>
      <TableCell>{propsUser.language}</TableCell>
      <TableCell>
        <div style={styles.verticalAlignColumn}>
          <Switch
            checked={propsUser.weeklyEmail}
            onChange={handleWeeklyEmailToggle}
            sx={styles.weeklyEmailToggle}
          />
        </div>
      </TableCell>
      <TableCell>
        <div style={styles.verticalAlignColumn}>
          <Switch
            checked={propsUser.monthlyEmail}
            onChange={handleMonthlyEmailToggle}
            sx={styles.monthlyEmailToggle}
          />
        </div>
      </TableCell>
      <TableCell>

        <IconButton
          sx={styles.settingsIconButton}
          aria-label="Settings"
          onClick={showEditPermissionsDialog}
        >
          <SettingsIcon />
        </IconButton>

        <EditUserPermissionsDialog
          key={`EditUserPermissionsDialog${propsUser.id}`}
          open={editPermissionsDialogOpen}
          onClose={hideEditPermissionsDialog}
          account={propsAccount}
          user={propsUser}
        />
      </TableCell>
      <TableCell>

        <IconButton
          sx={styles.keyIconButton}
          onClick={createLoginLink}
          aria-label="Key">
          <KeyIcon />
        </IconButton>

        <Dialog
          title={`${displayName} (${propsUser.email})`}
          open={loginLinkDialogOpen}
          onClose={handleLoginLinkDialogClose}
        >

          <DialogContent>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isDemoLink}
                    onChange={handleDemoLinkToggle}
                    sx={styles.demoLinkToggle}
                  />
                }
                label="Demo data in reports" />
            </FormGroup>
            <TextField
              id="text-field-controlled"
              value={loginLink}
              fullWidth={true}
            />
            <p>
              The link will expire in 14 days.
            </p>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoginLinkDialogClose}
            >
              Close
            </Button>
          </DialogActions>

        </Dialog>

      </TableCell>
      <TableCell>

        <IconButton
          style={styles.arrowForwardIconButton}
          onClick={impersonate}
          disabled={impersonationLoading}
          aria-label="Arrow Forward">
          <ArrowForwardIcon />
        </IconButton>

      </TableCell>
      <TableCell>
        <a
          style={styles.link}
          onClick={impersonateReportsNew}
          {...{ disabled: !!impersonationLoading }}
        > Reports </a>
        <br />
        <a
          style={styles.link}
          onClick={impersonateBuilderNew}
          {...{ disabled: !!impersonationLoading }}

        > Builder </a>
      </TableCell>
    </TableRow>
  );

}



// export default createFragmentContainer(
//   UserListItem,
//   {
//   userlistitemaccount: graphql`
//     fragment UserListItem_account on Account {
//       ...EditUserPermissionsDialog_account
//     }`
//   ,
//   userlistitemuser: graphql`
//     fragment UserListItem_user on User {
//       id
//       name
//       firstName
//       lastName
//       email
//       language
//       weeklyEmail
//       monthlyEmail
//       ...EditUserPermissionsDialog_user
//     }`,
//   }
// );
