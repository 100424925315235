
import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { UpdateAccountActiveMutation } from '../__generated__/UpdateAccountActiveMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation UpdateAccountActiveMutation(
    $input: UpdateAccountActiveInput!
  ) {
    updateAccountActive(input: $input) {
      account {
        id
        active
      }
    }
  }
`;


export const useUpdateAccountActiveMutation = ():[(config: UseMutationConfig<UpdateAccountActiveMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<UpdateAccountActiveMutation>(mutation);
  return [commit, isInFlight];
};






// import {commitMutation, graphql} from 'react-relay';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation UpdateAccountActiveActiveMutation(
//     $input: UpdateAccountActiveActiveInput!
//   ) {
//     UpdateAccountActiveActive(input: $input) {
//       account {
//         id
//         active
//       }
//     }
//   }
// `;

// function commit({accountId, active}) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           accountId,
//           active,
//         },
//       },
//     },
//   );
// }

// export default {commit};
