/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ImpersonateAccountInput = {
    accountId: string;
    callbackUrl: string;
    clientMutationId?: string | null | undefined;
};
export type ImpersonateAccountMutationVariables = {
    input: ImpersonateAccountInput;
};
export type ImpersonateAccountMutationResponse = {
    readonly impersonateAccount: {
        readonly url: string | null;
    } | null;
};
export type ImpersonateAccountMutation = {
    readonly response: ImpersonateAccountMutationResponse;
    readonly variables: ImpersonateAccountMutationVariables;
};



/*
mutation ImpersonateAccountMutation(
  $input: ImpersonateAccountInput!
) {
  impersonateAccount(input: $input) {
    url
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ImpersonateAccountPayload",
    "kind": "LinkedField",
    "name": "impersonateAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImpersonateAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImpersonateAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c259ad95160094b101b00e79f99bf9a5",
    "id": null,
    "metadata": {},
    "name": "ImpersonateAccountMutation",
    "operationKind": "mutation",
    "text": "mutation ImpersonateAccountMutation(\n  $input: ImpersonateAccountInput!\n) {\n  impersonateAccount(input: $input) {\n    url\n  }\n}\n"
  }
};
})();
(node as any).hash = '7611dec44ec72a71fff0ed1679f7bb7a';
export default node;
