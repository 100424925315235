import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useDebounce } from '../../../../hooks';



/**
 * OrderBy
 * @param props 
 * @returns 
 */
export default function OrderByDropdown(props: any) {

  const { debounceTime, onChange, options, sx } = props;

  const styles = sx || {};

  const [inputValue, setInputValue] = useState<string>('');

  const debouncedValue = useDebounce(inputValue, (debounceTime || 300));

  /**
   * Use effect
   */
  useEffect(() => {
    onChange(inputValue);
    // eslint-disable-next-line
  }, [debouncedValue])


  /**
   * Set value and call onChange
   */
  function handleChange(newValue: any) {
    setInputValue(newValue.target.value);
  }

  /**
   * Return
   */
  return <div
    style={{ minWidth: 150 }}
  >

    <Select
      sx={{ ...styles }}
      multiple={false}
      value={inputValue}
      onChange={handleChange}

    >
      {
        options.map((v: any) =>
          <MenuItem
            key={`order-by-dropdown-${v.value}`}
            value={v.value}>
            {v.label}
          </MenuItem>
        )
      }
    </Select>

  </div >;

}
