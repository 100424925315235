import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { CreateReplacementDeviceMutation } from '../__generated__/CreateReplacementDeviceMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation CreateReplacementDeviceMutation(
    $input: CreateReplacementDeviceInput!
  ) {
    createReplacementDevice(input: $input) {
      device {
        id
        mongoId
        name
        account
        collectVersion
        expectActive
        lastFetched
        lastFetchedCollectVersion
        location
        status
        config {
          activityTimeout
          hardReset
          language
          languagesToShow
          startScreenText
          demo
          endMessageTime
          alertOnPageClose
          reverseSmileys
        }
      }
    }
  }
`;



export const useCreateReplacementDeviceMutation = (): [(config: UseMutationConfig<CreateReplacementDeviceMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<CreateReplacementDeviceMutation>(mutation);
  return [commit, isInFlight];
};



