import graphql from 'babel-plugin-relay/macro';

export const SurveysQuery = graphql`
  query SurveysQuery {

    adminSurveys {
      edges {
        node {
          id
          mongoId
          name
          isTemplate
          isDraft
        }
      }
    }

  }
`;
