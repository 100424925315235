import { useState } from 'react';
import { grey200 } from '../../../../theme';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  useUpdateAccountActiveMutation,
  useImpersonateAccountMutation,
} from '../../../../mutations';
import { API_URL, DEFAULT_LOCALE } from '../../../../config';
import store from 'store';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';



const styles = {
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  table: {
    tableLayout: 'auto',
    width: 'auto',
  },
  tableRow: {
    height: 'auto',
  },
  tableCell: {
    height: 'auto',
    padding: '0 50px 0 0',
  },
  card: {
    borderBottom: '1px solid ' + grey200,
  },
  deviceListWrapper: {
    marginBottom: 12,
  },
};

export function AccountListItem(props: any) {

  const account = props.account || {};

  // const account = useFragment(graphql`
  //   fragment AccountListItem_account on Account {
  //     id
  //     mongoId
  //     name
  //     identifier
  //     email
  //     language
  //     active
  //   }
  //   `, propsAccount);

  const [manageSurveysLoading, setManageSurveysLoading] = useState<boolean>(false);
  const [manageReportsLoading, setManageReportsLoading] = useState<boolean>(false);
  const [manageSurveysThroughBuilderLoading, setManageSurveysThroughBuilderLoading] = useState<boolean>(false);

  const [commitUpdateAccountActiveMutation] = useUpdateAccountActiveMutation();
  const [commitImpersonateAccountMutation] = useImpersonateAccountMutation();

  const navigate = useNavigate();

  //
  //
  //
  function handleActiveToggle(event: React.ChangeEvent<HTMLInputElement>) {

    commitUpdateAccountActiveMutation({
      variables: {
        input: {
          accountId: account.id,
          active: !!event.target.checked,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
      },
    });

  }

  //
  //
  //
  function manageSurveysThroughBuilder() {
    setManageSurveysThroughBuilderLoading(true);


    commitImpersonateAccountMutation({
      variables: {
        input: {
          accountId: account.id,
          callbackUrl: String(process.env.REACT_APP_BUILDER_URL || ''),
        },
      },
      updater: (store, payload) => {
        // 
        const url = (payload as any || {}).impersonateAccount.url;
        window.open(url);
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
        setManageSurveysThroughBuilderLoading(false);
      },
      onError: (error) => {
        setManageSurveysThroughBuilderLoading(false);
        // handleError(error);
        alert(error.message);
      },
    });

    // ImpersonateAccountMutation.commit(
    //   {
    //     accountId: account.id,
    //     callbackUrl: process.env.REACT_APP_BUILDER_URL,
    //   },
    //   (err: any, url: any) => {
    //     setManageSurveysThroughBuilderLoading(false);
    //     if (err) {
    //       alert(err);
    //     } else {
    //       window.open(url);
    //     }
    //   },
    // );
  }

  //
  //
  //
  function manageSurveys() {
    setManageSurveysLoading(true);

    commitImpersonateAccountMutation({
      variables: {
        input: {
          accountId: account.id,
          callbackUrl: String(process.env.REACT_APP_BUILDER_URL || ''),
        },
      },
      updater: (store, payload) => {
        // 
        const url = (payload as any || {}).impersonateAccount.url;
        window.open(url);
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
        setManageSurveysLoading(false);
      },
      onError: (error) => {
        setManageSurveysLoading(false);
        // handleError(error);
        alert(error.message);
      },
    });

    // ImpersonateAccountMutation.commit(
    //   {
    //     accountId: account.id,
    //     callbackUrl: 'http://control.touch-and-tell.se/#/login',
    //   },
    //   (err: any, url: any) => {
    //     setManageSurveysLoading(false);
    //     if (err) {
    //       alert(err);
    //     } else {
    //       window.open(url);
    //     }
    //   },
    // );
  }

  //
  //
  //
  function manageReports() {
    setManageReportsLoading(true);
    // ImpersonateAccountMutation.commit(
    //   {
    //     accountId: account.id,
    //     callbackUrl: 'https://reports.touch-and-tell.se/callback',
    //   },
    //   (err: any, url: any) => {
    //     setManageReportsLoading(false);
    //     if (err) {
    //       alert(err);
    //     } else {
    //       window.open(url);
    //     }
    //   },
    // );


    commitImpersonateAccountMutation({
      variables: {
        input: {
          accountId: account.id,
          callbackUrl: 'https://reports.touch-and-tell.se/callback',
        },
      },
      updater: (store, payload) => {
        // 
        const url = (payload as any || {}).impersonateAccount?.url;
        if (url) {
          window.open(url);
        }
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
        setManageReportsLoading(false);
      },
      onError: (error) => {
        setManageReportsLoading(false);
        // handleError(error);
        alert(error.message);
      },
    });
  }

  //
  //
  //
  function token() {
    const storedAuth = store.get('auth') || {};
    return storedAuth.token;
  }

  //
  //
  //
  const navigateTo = (route: string) => {
    navigate(route);
  };



  return (

    <Grid
      container
      sx={{
        mb: 3,
        minWidth: '100%',
        maxWidth: '100%',
        width: '100%',
      }}
    >
      <Grid
        sx={{
          minWidth: '100%',
          maxWidth: '100%',
        }}
        item>
        <Accordion
          sx={{
            minWidth: '100%',
            borderRadius: '4px',
            overflow: 'hidden',
          }}
        >
          <AccordionSummary
            sx={{
              // pointerEvents: 'none',
              border: '1px solid #c4e2e7',
              borderRadius: '4px',
            }}
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  // pointerEvents: "auto",
                }}
              />
            }
          >

            <Grid
              container
              spacing={2}
              justifyContent="s"
              flexDirection="row">
              <Grid
                sx={{
                  width: '50%',
                }}
                item>
                <Grid container flexDirection="column">
                  <Grid
                    sx={{
                      marginBottom: 0,
                    }}
                    item>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                      }}
                      variant="h6">
                      {account ? account.name : ''}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">
                      {account.identifier}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container flexDirection="column">
                  <Grid item>
                    <Typography>
                      ID: {account.mongoId}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Language: {account.language || DEFAULT_LOCALE}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container flexDirection="column">
                  <Grid item>

                    <Button
                      variant="contained"
                      disabled={false}
                      color="primary"
                      onClick={() => navigateTo(`/account/${account.id}`)}
                    >
                      Details
                    </Button>

                  </Grid>
                </Grid>
              </Grid>

            </Grid>

          </AccordionSummary>
          <AccordionDetails>

            <Grid container flexDirection="column">
              <Grid item>



                <Grid
                  spacing={1}
                  sx={{
                    paddingTop: 4,
                    paddingBottom: 4,
                  }}
                  container>

                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={account.active}
                            onChange={handleActiveToggle}
                          />
                        }
                        labelPlacement="end"
                        label="Active" />
                    </FormGroup>
                  </Grid>

                  <Grid item>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        API_URL +
                        `/formattedAnswers?filter:account=${account.mongoId}` +
                        `&sort:time=descending&limit=10&token=${token()}`
                      }
                      style={styles.link}
                    >
                      Export answers &rarr;
                    </a>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={manageSurveysThroughBuilderLoading}
                      color="primary"
                      onClick={manageSurveysThroughBuilder}
                    >
                      {manageSurveysThroughBuilderLoading ? 'Loading...' : 'New Builder'}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={manageSurveysLoading}
                      color="primary"
                      onClick={manageSurveys}
                    >
                      {manageSurveysLoading ? 'Loading...' : 'Manage surveys'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={manageReportsLoading}
                      color="primary"
                      onClick={manageReports}
                    >
                      {manageReportsLoading ? 'Loading...' : 'Manage reports'}
                    </Button>
                  </Grid>

                </Grid>

              </Grid>



              {/* <Grid
                sx={{
                  // ...styles.deviceListWrapper,
                  overflowX: 'scroll',
                  width: '100%',
                }}
                item>
                <DeviceList
                  account={account} />
              </Grid> */}
              {/* 
              <Grid
                sx={{
                  overflowX: 'scroll',
                  width: '100%',
                }}
                item>
                <UserList
                  account={account} />
              </Grid> */}


            </Grid>

          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>

  );
}

// export default createFragmentContainer(
//   AccountListItem,
//   { accountlistitem: graphql`
//     fragment AccountListItem_account on Account {
//       id
//       mongoId
//       name
//       identifier
//       email
//       language
//       active
//       ...DeviceList_account
//       ...UserList_account
//     }
//   `},
// );

