import graphql from 'babel-plugin-relay/macro';
import { useMutation, UseMutationConfig } from 'react-relay';
import { CreateAccountMutation } from '../__generated__/CreateAccountMutation.graphql';
import { Disposable } from 'relay-runtime';



const mutation = graphql`
  mutation CreateAccountMutation(
    $input: CreateAccountInput!
  ) {
    createAccount(input: $input) {
      account {
        id
        mongoId
        name
        identifier
        email
        language
      }
    }
  }
`;



export const useCreateAccountMutation = ():[(config: UseMutationConfig<CreateAccountMutation>) => Disposable, boolean] => {
  const [commit, isInFlight] = useMutation<CreateAccountMutation>(mutation);
  return [commit, isInFlight];
};



// import {commitMutation, graphql} from 'react-relay';
// import {ConnectionHandler} from 'relay-runtime';
// import environment from 'data/environment';

// const mutation = graphql`
//   mutation CreateAccountMutation(
//     $input: CreateAccountInput!
//   ) {
//     createAccount(input: $input) {
//       account {
//         id
//         mongoId
//         name
//         identifier
//         email
//         language
//       }
//     }
//   }
// `;

// function commit({name, identifier, password, language}) {
//   commitMutation(
//     environment,
//     {
//       mutation,
//       variables: {
//         input: {
//           name,
//           identifier,
//           password,
//           language,
//         },
//       },
//       updater: (store) => {
//         const payload = store.getRootField('createAccount');
//         const newAccount = payload.getLinkedRecord('account');
//         const connection = ConnectionHandler.getConnection(
//           store.getRoot(),
//           'AccountList_accounts',
//         );
//         const newEdge = ConnectionHandler.createEdge(
//           store,
//           connection,
//           newAccount,
//           'AccountEdge',
//         );
//         ConnectionHandler.insertEdgeBefore(connection, newEdge);
//       },
//     },
//   );
// }

// export default {commit};
