/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateDeviceTagsInput = {
    deviceId: string;
    tags?: Array<string | null> | null | undefined;
    clientMutationId?: string | null | undefined;
};
export type UpdateDeviceTagsMutationVariables = {
    input: UpdateDeviceTagsInput;
};
export type UpdateDeviceTagsMutationResponse = {
    readonly updateDeviceTags: {
        readonly device: {
            readonly id: string;
            readonly tags: ReadonlyArray<string | null> | null;
        } | null;
    } | null;
};
export type UpdateDeviceTagsMutation = {
    readonly response: UpdateDeviceTagsMutationResponse;
    readonly variables: UpdateDeviceTagsMutationVariables;
};



/*
mutation UpdateDeviceTagsMutation(
  $input: UpdateDeviceTagsInput!
) {
  updateDeviceTags(input: $input) {
    device {
      id
      tags
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateDeviceTagsPayload",
    "kind": "LinkedField",
    "name": "updateDeviceTags",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDeviceTagsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateDeviceTagsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e16e434d48b51634bb65e2d936af9749",
    "id": null,
    "metadata": {},
    "name": "UpdateDeviceTagsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDeviceTagsMutation(\n  $input: UpdateDeviceTagsInput!\n) {\n  updateDeviceTags(input: $input) {\n    device {\n      id\n      tags\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e72d5266632ae674e25d566a35ffc362';
export default node;
