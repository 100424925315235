import { useRouteError } from "react-router-dom";



export const ErrorPage = () => {

  const error: any = useRouteError();

  return (
    <div className="page-layout">
      <div
        style={{
          textAlign: 'center',
          fontFamily: 'sans-serif',
        }}
        className="content-layout">
        <h1 id="page-title" className="content__title">
          Not Found
        </h1>
        <p>An unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </div>
  );
};

