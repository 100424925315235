import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function Login() {

  const {
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const navigate = useNavigate();

  // console.log('CallbackPage');
  // console.log('_______________');
  // console.log('user', user);
  // console.log('error', error);
  // console.log('token', 1);
  // console.log('isAuthenticated', isAuthenticated);

  useEffect(() => {
    (async () => {

      if (!isLoading && isAuthenticated && !error) {
        navigate('/accounts');
      } else {
        loginWithRedirect();
      }

    })()
  }, [navigate, loginWithRedirect, isLoading, isAuthenticated, error]);


  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/accounts',
      },
      authorizationParams: {
        prompt: 'login',
      },
    });
  };

  //
  // // https://auth0.github.io/auth0-spa-js/interfaces/redirectloginoptions.html#ui_locales
  // loginWithRedirect({ ui_locales: 'en-GB' });
  //

  return <div>
    <button className="button__login" onClick={handleLogin}>
      Log In
    </button>
  </div>;
}
