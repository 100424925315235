import graphql from 'babel-plugin-relay/macro';



export const AccountQuery = graphql`
query AccountQuery($accountId: ID) {

  accountById(accountId: $accountId) {
    id
    mongoId
    name
    identifier
    email
    language
    active
  }

  accounts {
    edges {
      node {
        id
        mongoId
        name
      }
    }
  }

}
`;






// export const AccountQuery = graphql`
// query AccountQuery($accountId: ID) {

//   accountById(accountId: $accountId) {
//     id
//     mongoId
//     name
//     identifier
//     email
//     language
//     active
//     devices(
//       first: 2147483647 # max GraphQLInt
//     ) @connection(key: "DeviceList_devices") {
//       edges {
//         node {
//           id
//           name
//           location
//           timezone
//           tags
//           mongoId
//           collectVersion
//           expectActive
//           lastFetched
//           isOnline

//           config {
//             activityTimeout
//             hardReset
//             language
//             languagesToShow
//             startScreenText
//             demo
//             endMessageTime
//             alertOnPageClose
//             reverseSmileys
//           }

//         }
//       }
//     }

//     users(
//       first: 2147483647 # max GraphQLInt
//     ) @connection(key: "UserList_users") {
//       edges {
//         node {
//           id
//           name
//           firstName
//           lastName
//           email
//           language
//           weeklyEmail
//           monthlyEmail
//           advancedMode
//           accessToDevices {
//             id
//           }
//           accessToReports {
//             id
//           }
//         }
//       }
//     }

//     devices(
//       first: 2147483647 # max GraphQLInt
//     ) @connection(key: "EditUserPermissionsDialog_devices") {
//       edges {
//         node {
//           id
//           name
//         }
//       }
//     }

//     reports(
//       first: 2147483647 # max GraphQLInt
//     ) @connection(key: "EditUserPermissionsDialog_reports") {
//       edges {
//         node {
//           id
//           name
//         }
//       }
//     }

//   }

// }
// `;
