import Color from 'color';

const tntBlue = '#00abc5';
const tntFadedBlue = Color(tntBlue).desaturate(0.5).fade(0.4).string();
const tntAccent = '#ff7100';
const tntGreen = '#50c457';
const tntFadedGreen = Color(tntGreen).desaturate(0.5).fade(0.4).string();
const tntRed = '#d95b4e';
const tntFadedRed = Color(tntRed).desaturate(0.5).fade(0.4).string();
 

const primaryColor = '#00abc5';
const primaryLightColor = '#5eddf8';
const primaryLightColor2 = '#4ac2d5';
const primaryDarkColor = '#007b94';
const primaryTextColor = '#ffffff';

const secondaryColor = '#ff990c';
const secondaryLightColor = '#ffca4b';
const secondaryDarkColor = '#c66a00';
const secondaryTextColor = '#ffffff';

const grayColorLight = '#e0e0e0';
const grayColor = 'rgba(0, 0, 0, 0.6)';
const grayColorBackground = '#fbfbfb';
const grayColorBackground2 = '#f7f7f7';

const red500 = '#f44336';
const grey200 = '#eeeeee';
const grey300 = '#e0e0e0';
const grey400 = '#bdbdbd';
const grey500 = '#9e9e9e';
const grey100 = '#f5f5f5';
const white = '#ffffff';
const darkBlack = 'rgba(0, 0, 0, 0.87)';
const fullBlack = 'rgba(0, 0, 0, 0.0)';

export {
  primaryColor,
  primaryLightColor,
  primaryLightColor2,
  primaryDarkColor,
  primaryTextColor,

  secondaryColor,
  secondaryLightColor,
  secondaryDarkColor,
  secondaryTextColor,

  grayColorLight,
  grayColor,
  grayColorBackground,
  grayColorBackground2,

  red500,
  grey200,
  grey300,
  grey400,
  grey500,
  grey100,
  white,
  darkBlack,
  fullBlack,

  tntBlue,
  tntFadedBlue,
  tntAccent,
  tntGreen,
  tntFadedGreen,
  tntRed,
  tntFadedRed,

};


