import { useState, MouseEvent } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import Logout from '@mui/icons-material/Logout';
import { useAuth0 } from "@auth0/auth0-react";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { standardTransition, primaryColor } from '../../theme';
// import { 
//   helper,
//   notificationService,
// } from '../../services';

// import { useLocale } from '../../contexts';
import { useNavigate } from 'react-router-dom';


const menuBnSx: any = {
  color: 'white',
  transition: standardTransition,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: primaryColor,
  whiteSpace: 'nowrap',

  '&:hover': {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'white',
  },
};

const logoStyles: any = {
  maxHeight: '40px',
  cursor: 'pointer',
  borderRadius: '50%',
  border: '1px solid white',
  marginRight: '16px',
};



export function Header() {

  // const [ commitCreateSurvey ] = useCreateSurveyMutation();

  const navigate = useNavigate();
  // const { intl } = useLocale();
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { name, picture } = ((user || {}) as any);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const navigateTo = (route: string) => {
    navigate(route);
  };

  // /**
  //  * Open support link in a new tab
  //  */
  // function openSupport(): void {
  //   const href = String(process.env.REACT_APP_SUPPORT_URL || '');
  //   // helper.openInNewTab(href);
  //   return;
  // }

  // /**
  //  * Open reports app link in a new tab
  //  */
  // function openReports(): void {
  //   const href = String(process.env.REACT_APP_REPORTS_BASE_URL || '');
  //   // helper.openInNewTab(href);
  //   return;
  // }

  /**
   * Perform logout
   */
  function handleLogout(): void {
    localStorage.removeItem('token');

    // logout({ returnTo: window.location.origin });

    const returnUrl = window.location.origin;
    logout({
      async openUrl(url) {
        localStorage.removeItem('accessToken');
        window.location.replace(returnUrl);
      }
    });
  }

  // /**
  //  * Create a new survey
  //  */
  //  function handleCreateSurvey() {
  //   // commitCreateSurvey({
  //   //   variables: {
  //   //     input: {
  //   //       name: intl.formatMessage({id: 'newSurvey'}),
  //   //     }
  //   //   },
  //   //   updater: (store: any, payload: any) => {
  //   //     const surveyId = (payload as any || {}).createSurvey.survey.id;
  //   //     notificationService.addSurvey(surveyId);
  //   //     history.push(`/wizard/${surveyId}`);
  //   //   }
  //   // });
  // }

  return (

    <Box sx={{
      padding: '8px 24px',
      backgroundColor: primaryColor,
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        'button + button': {
          ml: 1,
        },
      }}>
        <img
          style={logoStyles}
          onClick={() => navigateTo('/wizard-entry')}
          src="/logo192.png"
          alt='logo' />
        <Button onClick={() => navigateTo('/surveys')} sx={menuBnSx}>
          Surveys
        </Button>
        <Button onClick={() => navigateTo('/accounts')} sx={menuBnSx}>
          Accounts
        </Button>
        <Button onClick={() => navigateTo('/devices')} sx={menuBnSx}>
          Devices
        </Button>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
          <Tooltip title={'Settings'}>
            <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
              <Avatar
                alt={name}
                src={picture}
                sx={{ width: 32, height: 32 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {

            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,

            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >


        <MenuItem onClick={handleLogout} >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {'Logout'}
        </MenuItem>
      </Menu>

    </Box>

  );
}