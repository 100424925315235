import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useDebounce } from '../../../../hooks';



const buttonStyle = {
  padding: 4,
  minWidth: 24,
  marginRight: 2,
  marginLeft: 2,
};

/**
 * OrderBy
 * @param props 
 * @returns 
 */
export default function OrderBy(props: any) {

  const { debounceTime, onChange, value, sx } = props;

  const styles = sx || {};

  const [inputValue, setInputValue] = useState<any>(value);

  const debouncedValue = useDebounce(inputValue, (debounceTime || 300));


  /**
   * Use effect
   */
  useEffect(() => {
    onChange(inputValue);
    // eslint-disable-next-line
  }, [debouncedValue])


  /**
   * Set value and call onChange
   */
  function handleChange(newValue: any) {
    setInputValue(newValue);
  }


  /**
   * Return
   */
  return <div style={{ minWidth: 150 }}>
    <Button
      style={{ ...buttonStyle }}
      sx={{ ...styles }}
      variant={inputValue === -1 ? 'contained' : 'outlined'}
      color="primary"
      onClick={() => handleChange(-1)} // mongodb DESC sorting
    >
      ⌄
    </Button>
    <Button
      style={{ ...buttonStyle }}
      sx={{ ...styles }}
      variant={inputValue === 1 ? 'contained' : 'outlined'}
      color="primary"
      onClick={() => handleChange(1)} // mongodb ASC sorting
    >
      ^
    </Button>
    <Button
      style={{ ...buttonStyle }}
      sx={{ ...styles }}
      variant="outlined"
      color="primary"
      onClick={() => handleChange(null)}
    >
      X
    </Button>
  </div>;

}
