import {
  MULTIPLE_CHOICE_TYPES,
} from '../config';

export const helper = (function(): any {

  /**
   * Open link in a new tab
   * @param href 
   */
  function openInNewTab(href: string): void {
    if (!href) {
      return;
    }
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = href;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  }

  /**
   * Open link to a report in a new tab
   * @param token 
   * @param reportId 
   */
  function openLinkToReports(token: string, reportId?: string): void {

    const url = reportId ? `/reports/${reportId}` : '/callback';
    const baseUrl = String(process.env.REACT_APP_REPORTS_BASE_URL || '');
    if(!token || !baseUrl) {
      return;
    }

    const href = `${baseUrl}${url}?token=${token}`;
    return openInNewTab(href);
  }

  /**
   * Open link preview a survey
   */
  function openLinkToPreview(params: {[key: string]: string}): void {

    let previewDeviceId = String(process.env.REACT_APP_PREVIEW_DEVICE_ID || '');

    if (params.device) {
      previewDeviceId = params.device;
    }

    const baseUrl = String(process.env.REACT_APP_COLLECT_URL || '');
    if (!baseUrl) {
      return;
    }
    const queryParams = new URLSearchParams();

    queryParams.set('device', previewDeviceId);
    queryParams.set('redirect', 'true');
    queryParams.set('demo', 'true');

    Object.keys(params).forEach((key: string) => {
      queryParams.set(key, params[key]);
    });

    const url = `${baseUrl}/?${queryParams.toString()}`;

    return helper.openInNewTab(url);
  }

  /**
   * Open link device in demo mode
   */
  function openDeviceDemo(deviceId: string): void {

    const baseUrl = String(process.env.REACT_APP_COLLECT_URL || '');
    if (!baseUrl) {
      return;
    }
    const queryParams = new URLSearchParams();

    queryParams.set('device', deviceId);
    queryParams.set('redirect', 'true');
    queryParams.set('demo', 'true');

    const url = `${baseUrl}/?${queryParams.toString()}`;

    return helper.openInNewTab(url);
  }

  /**
   * Check if any translations are missing in a question
   */
  function hasMissingTranslations(question: { 
    languages: string[],
    text: any,
    options: any,
    type: string,
  }): null | string[] {

    const missingTranslations: string[] = [];

    function addLocale(code: string) {
      if (!missingTranslations.includes(code)) {
        missingTranslations.push(code);
      }
    }

    let requiredLocales: string[] = [];
    if (question && Array.isArray(question.languages)) {
      requiredLocales = question.languages;
    }

    // Checking question text (question itself)
    if (question?.text && Array.isArray(question?.text)) {
      const languages: string[] = [];
      question?.text.forEach((translation: any) => {
        // If text is empty string translation is missing
        if (!translation.text) {
          addLocale(translation.language);
        }
        languages.push(translation.language);
      });
      if (requiredLocales.length) {
        requiredLocales.forEach((l: string) => {
          if (!languages.includes(l)) {
            addLocale(l);
          }
        });
      }
    }
  
    // Checking translations of question options
    if (!missingTranslations && MULTIPLE_CHOICE_TYPES.includes(question?.type)) {
      if (question?.options && Array.isArray(question?.options)) {
        question?.options.forEach((option: any) => {
          if (option.text && Array.isArray(option.text)) {
            const languages: string[] = [];
            option.text.forEach((translation: any) => {
              // If text is empty string translation is missing
              if (!translation.text) {
                addLocale(translation.language);
              }
              languages.push(translation.language);
            });
  
            if (requiredLocales.length) {
              requiredLocales.forEach((l: string) => {
                if (!languages.includes(l)) {
                  addLocale(l);
                }
              });
            }
          }
        });
      }
    }

    return missingTranslations.length ? missingTranslations : null;
  }

  /**
   * Check if there are missing translations
   */
  function hasMissingTranslationsList(list: any[]): null | any[] {

    const missingTranslationsList: any[] = [];

    if (!list || !Array.isArray(list)) {
      return null;
    }

    list.forEach((q: any) => {
      if (hasMissingTranslations(q)) {
        missingTranslationsList.push(q);
      }
    });

    return missingTranslationsList.length ? missingTranslationsList : null;
  }

  /**
   * Get a list of missing languages from the list of questions
   */
  function getMissingLocales(list: any[]): null | any[] {
    const missingList: any[] = [];

    if (!Array.isArray(list)) {
      return null;
    }

    list.forEach((q: any) => {
      const missing = hasMissingTranslations(q);
      if (missing) {
        missing.forEach((l: string) => {
          if (!missingList.includes(l)) {
            missingList.push(l);
          }
        });
      }
    });

    return missingList.length ? missingList : null;
  }

  /**
   * Edges to a list of object
   */
  function edgesToList(item: any): any[] {
    return item && item?.edges ? item?.edges.map((edge: any) => edge ? edge.node : null).filter(Boolean) : [];
  }

  return {
    openDeviceDemo,
    openInNewTab,
    openLinkToReports,
    openLinkToPreview,
    hasMissingTranslations,
    hasMissingTranslationsList,
    edgesToList,
    getMissingLocales,
  };

})();
