import { ChangeEvent, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {
  useCreateUserMutation
} from '../../../../mutations';
import {
  DEFAULT_LOCALE,
  LOCALE_EN_GB,
  LOCALE_SV_SE,
} from '../../../../config';
import Grid from '@mui/material/Grid';



const styles = {
  firstNameInput: {
    width: 500,
  },
  lastNameInput: {
    width: 500,
  },
};

export function CreateUserDialog(props: any) {

  const { open, onClose, account } = props;

  const [commitCreateUserMutation] = useCreateUserMutation();

  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [language, setLanguage] = useState<string>(account.language || DEFAULT_LOCALE);

  function updateEmail(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function updateFirstName(event: ChangeEvent<HTMLInputElement>) {
    setFirstName(event.target.value);
  }

  function updateLastName(event: ChangeEvent<HTMLInputElement>) {
    setLastName(event.target.value);
  }

  function updateLanguage(event: SelectChangeEvent) {
    setLanguage(event.target.value);
  }

  function save() {

    commitCreateUserMutation({
      variables: {
        input: {
          accountId: account.id,
          email: email,
          firstName: firstName,
          lastName: lastName,
          language: language,
          advancedMode: false,
        },
      },
      updater: (store, payload) => {
        // 
      },
      onCompleted: () => {
        // success(intl.formatMessage({id: 'notificationQuestionWasCreated'}));
      },
      onError: (error) => {
        // handleError(error);
      },
    });

    // CreateUserMutation.commit({
    //   accountId: account.id,
    //   email: email,
    //   firstName: firstName,
    //   lastName: lastName,
    //   language: language,
    // });

    setEmail('');
    setFirstName('');
    setLastName('');
    setLanguage(account.language || DEFAULT_LOCALE);

    onClose();
  }

  function cancel() {

    setEmail('');
    setFirstName('');
    setLastName('');
    setLanguage(account.language || DEFAULT_LOCALE);

    onClose();
  }



  return (
    <Dialog
      title="Create a new user"
      open={open}
      onClose={onClose}
    >

      <DialogContent>

        <Grid container flexDirection="column">
          <Grid
            sx={{
              mb: 1,
            }}
            item>
            <TextField
              label="Email"
              value={email}
              onChange={updateEmail}
            />
          </Grid>
          <Grid
            sx={{
              mb: 1,
            }}
            item>
            <TextField
              label="First Name"
              value={firstName}
              onChange={updateFirstName}
              style={styles.firstNameInput}
            />
          </Grid>
          <Grid
            sx={{
              mb: 1,
            }}
            item>
            <TextField
              label="Last Name"
              value={lastName}
              onChange={updateLastName}
              style={styles.lastNameInput}
            />
          </Grid>
          <Grid
            sx={{
              mb: 1,
            }}
            item>
            <FormControl fullWidth>
              <InputLabel id="select-label">Language</InputLabel>
              <Select
                labelId="select-label"
                value={language}
                label="Language"
                onChange={updateLanguage}
              >
                <MenuItem value={LOCALE_SV_SE}>
                  Swedish
                </MenuItem>
                <MenuItem value={LOCALE_EN_GB}>
                  English
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

        </Grid>

      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={save}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

}

// export default createFragmentContainer(
//   CreateUserDialog,
//   { createuserdialog: graphql`
//     fragment CreateUserDialog_account on Account {
//       id
//       language
//     }
//   `},
// );



