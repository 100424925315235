import { useEnvironment } from '../services';
import { IRelayRootProps } from '../interfaces';
import {
  RelayEnvironmentProvider,
} from 'react-relay/hooks';
import { API_URL } from '../config';



export function RelayRoot({ token, children }: IRelayRootProps) {

  const relayEnvironment = useEnvironment(token, API_URL)

  return (
    <RelayEnvironmentProvider environment={relayEnvironment}>
      {children}
    </RelayEnvironmentProvider>
  )
}