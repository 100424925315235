
import { useAuth0 } from "@auth0/auth0-react";
import { AppLoader, ErrorPage, RootLayout } from "../views";
import {
  // Home,
  Login,
} from '../views';
import { CallbackPage } from "../views/CallbackPage/CallbackPage";
import { NotFoundPage } from "../views/NotFoundPage/NotFoundPage";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Suspense } from "react";

import { AuthenticationGuard } from "./AuthenticationGuard";
import DeviceList from '../views/DeviceList/DeviceList';
import AccountList from '../views/AccountList/AccountList';
import SurveyList from '../views/SurveyList/SurveyList';
import Account from '../views/Account/Account';



const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [

      // {
      //   path: "/home",
      //   element: <Home />,
      // },
      {
        path: "accounts",
        element: <AuthenticationGuard component={AccountList} />,
      },
      {
        path: "account/:accountId",
        element: <AuthenticationGuard component={Account} />,
      },
      {
        path: "surveys",
        element: <AuthenticationGuard component={SurveyList} />,
      },
      {
        path: "devices",
        element: <AuthenticationGuard component={DeviceList} />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "callback",
        element: <CallbackPage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },

    ],
  },
]);



export const Main = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <Suspense fallback={<AppLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};


